import * as React from 'react';

/** Components */
import AppRouter from '../Navigation/Routes';

/** Ant design stylesheet */
//import 'antd/dist/antd.css';
import 'antd/dist/reset.css';

const App: React.FC = () => {
  return ( 
      
    <div className="App"> 
      <AppRouter />   
    
    </div>
    );
};

export default App;
