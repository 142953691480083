import axios from 'axios';

const BASE_URL = process.env.REACT_APP_KPM_API; //"https://api.kpmdev.one"
const PORTAL_URL = process.env.REACT_APP_PORTAL_API; //"https://api-portal.kpmdev.one";

class CommonService {
  postApiExecutor(url: any, params: any, token?:any, customHeader?:any, datestr?:any, kpmHeader?:any) {
    const instanceAx = axios.create();
    let config = {};
    if(customHeader){
      config = {
        headers: {'Content-Type': 'application/json', 'X-Authorization': `${token}`, 'loginTime':datestr},
      }
    } else if(kpmHeader){
      config = {
        headers: {'Content-Type': 'application/json', 'COG-Authorization': `Bearer C#~#${token}`},
      }
    } else {
      config = {
        headers: {'Content-Type': 'application/json', 'COG-Authorization': `Bearer C#~#${token}`},
      }
    }
    return instanceAx.post(`${BASE_URL}/${url}`, params, config);
  }

  portalApiCall(url: any, params: any, token?:any) {
    const instanceAx = axios.create();
    let config = {
      headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
    }
    return instanceAx.post(`${PORTAL_URL}/${url}`, params, config);
  }
}
export default new CommonService();
