import * as React from 'react';
import { Auth } from 'aws-amplify';
import { RouteComponentProps, withRouter } from 'react-router';
import {notification } from 'antd';

/** App Constatns */
import { AUTH_USER_TOKEN_KEY } from '../../Utils/constants';

const DashBoardContainer: React.FC<RouteComponentProps> = props => {
  const handleLogout = async () => {
    const { history } = props;
    try {
      await Auth.signOut({ global: true }).then(() => {
        localStorage.removeItem(AUTH_USER_TOKEN_KEY);
        history.push('/login');
      });
    } catch (err:any) {
      notification.error({ message: err.message });
    }
  };

  return (
    <div className="container-sm">
      <button type="submit" className="btn btn-primary btn-block" onClick={() => handleLogout()}>Sign out</button>
      <button type="submit" className="btn btn-primary btn-block">Visit Konnexsion</button>
      <button type="submit" className="btn btn-primary btn-block">Visit Portal</button>
    </div>
  );
};

export default withRouter(DashBoardContainer);
