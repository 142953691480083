import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import CommonService from '../../../Services/CommonService';
import { AUTH_USER_DEVICE_KEY, AUTH_USER_TOKEN_KEY } from '../../../Utils/constants';
import { Auth } from 'aws-amplify';

import Select from 'react-select';

const SideBar = (props:any) => {
  const [portalList, setPortalList] = useState([] as any)
  const [longonData, setLongonData] = useState({} as any)
  const [validKPMEntities, setValidKPMEntities] = useState([] as any)
  const [validPortalEntities, setValidPortalEntities] = useState([] as any)
  const [menulist, setMenuList] = useState([] as any)
  const [entitysitelist, setEntitysitelist] = useState([] as any)
  const [entitylistdata, setEntitylistdata] = useState([] as any)
  const [selectedentity, setSelectedentity] = useState(null as any)
  useEffect(() => {
    if (props.userId !== 0) {
      CommonService.portalApiCall("rest/getuserportalsites", {userId: props.userId}
      ).then(response => {
        const data = response.data.customData[0].array_to_json;
        if (data != null) {
          setPortalList(JSON.parse(data.value));
        }
      },
      err => {

      })

      getLogonDetails();
      if(!(entitylistdata && entitylistdata.length>0 && selectedentity)){
        getentitylist();
      }
    }
  }, [props.userId]);

  const sortBy = (sourceData:any,prop: string)=> {
    return sourceData.sort((a:any, b:any) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }
  const getentitylist = ()=>{
    let params = {
      "userId": props.userId, 
      "entityId": null,
      "relatedEntityId":null,
      "type":"account",
      "includeInactive":false
    };
    CommonService.postApiExecutor('getentitylist', params,localStorage.getItem(AUTH_USER_TOKEN_KEY)).then((data:any) =>  {
      if (data.data.errorCode == 0) {
            let entityListDta = (data.data && data.data.customData)?data.data.customData:[];
            setEntitylistdata(entityListDta);
            let selectedenty = entityListDta.find((item:any) =>item.is_default_entity);
            setSelectedentity(selectedenty?selectedenty:entityListDta[0]);
            fetchdynamicmenuitem(selectedenty?selectedenty.entity_id:entityListDta[0].entity_id);
        }},
        err => {
  
    }); 
  }

 const getsitesforuserentity = (entityId:any)=>{
  var params = {
    "userId":props.userId,
    "entityId":entityId
  }

  CommonService.postApiExecutor('getsitesforuserentity', params,localStorage.getItem(AUTH_USER_TOKEN_KEY)).then((data:any) =>  {
      if (data.data.errorCode == 0) {
        if(data.data.customData){
          let siteListResponse = (data.data && data.data.customData && data.data.customData[0] && data.data.customData[0].array_to_json && data.data.customData[0].array_to_json.value)?JSON.parse(data.data.customData[0].array_to_json.value):[];
          setEntitysitelist(siteListResponse);
        }
      } else {
        
      }
    },
    err => {

  });
 }
 const fetchdynamicmenuitem = (entityId:any, needReset?:boolean)=>{

    if(needReset){
      let selectedenty = entitylistdata.find((item:any) =>item.entity_id === entityId);
      setSelectedentity(selectedenty);
    }
    getsitesforuserentity(entityId);

    var params = {
      "userId":props.userId,
      "entityId":entityId,
      "menuIds":"3",
      "displayType":null
    }

    CommonService.postApiExecutor('fetchdynamicmenuitem', params,localStorage.getItem(AUTH_USER_TOKEN_KEY)).then((data:any) =>  {
        if (data.data.errorCode == 0) {
          if(data.data.customData){
            let menuListItems = data.data.customData;

            let menulst = JSON.parse(menuListItems.find((item:any)=>{return item.menu_id==3}).menu_details.value);
            setMenuList(menulst);
          }
        } else {
          
        }
      },
      err => {
  
    });
  }

  const getLogonDetails = ()=>{
    var today = new Date();
		var dd = today.getDate();
		var mm = today.getMonth() + 1; //January is 0!
		var yyyy = today.getFullYear();
		var hours = today.getHours();
		var minutes = today.getMinutes();
		var seconds = today.getSeconds();
    var timezone = function getTimeZone(dateObj:any) {
			var offset = dateObj.getTimezoneOffset(), o = Math.abs(offset);
			return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
		}
		var dateStr= yyyy+"-"+mm+"-"+dd+" "+hours+":"+minutes+":"+seconds+timezone(today);

    CommonService.postApiExecutor("rest/userlogon",{}, localStorage.getItem(AUTH_USER_TOKEN_KEY)
    ,true,dateStr).then((response:any) => {
      if (response.data.data.errorCode === 0) {
        setLongonData(response.data.data.customData);
        let logonData = response.data.data.customData;

        let validKPMEntities = logonData.entity.filter((x: { restrictions: string; }) => x.restrictions && JSON.parse(x.restrictions)['ex-konnexsion']);
        let validPortalEntities = logonData.entity.filter((x: { restrictions: string; }) => x.restrictions &&  JSON.parse(x.restrictions)['ex-portal']);
        
        setValidKPMEntities(validKPMEntities);
        setValidPortalEntities(validPortalEntities);
      }
    },
    err => {

    })
  }


  const ssoRedirection = async (params:any,app?:any,updated?:boolean)=>{
    
    if(updated){

      if(app === 'website' || app === 'portal'){
        params.site = app;
      }
      let deviceKey = localStorage.getItem(AUTH_USER_DEVICE_KEY);
      params.deviceKey = deviceKey;
      params.payload = JSON.stringify(params.payload);
      CommonService.postApiExecutor("requestsso", 
      params, 
      localStorage.getItem(AUTH_USER_TOKEN_KEY),false,null,true).then(response => {
        if (response.data.errorCode !== 0) {
  
        } else {
          let ssoToken = response.data.customData?response.data.customData.id:"";
          let destination = response.data.customData?response.data.customData.destinationDomain:"";

          if(response.data.customData.url){
             window.open(response.data.customData.url, '_blank');
          } else if(ssoToken && destination){
            if(app === "app2"){
              window.open(destination + "/auth?sso_token=" + ssoToken, '_blank');
            } else if(app === "portal"){
              window.open(destination + "/auth?sso_token=" + ssoToken, '_blank');
            }  else if(app === "app"){
              window.open(destination + "/auth?sso_token=" + ssoToken, '_blank');
            }
          }
        }
      })
    } else {
      const user: any = await Auth.currentAuthenticatedUser();
      params.userId = user.attributes["custom:kpmUserId"];
      params.payload.user_id = user.attributes["custom:kpmUserId"];
      params.payload.return_url = "/";
      params.payload = JSON.stringify(params.payload);
  
      CommonService.postApiExecutor("generatesso", 
      params, 
      user.signInUserSession.accessToken.jwtToken,false,null,true).then(response => {
        if (response.data.errorCode !== 0) {
  
        } else {
          let ssoToken = response.data.customData;
          window.open(params.destinationDomain + "/auth?sso_token=" + ssoToken, '_blank');
        }
      })
    }
  }

  const goToPortal = (val?:any) => {
    let dnsEndpoint = val?val.dns_endpoint:portalList[0].dns_endpoint;
    var ssoparam = {
      "entityId":val?val.entity_id:portalList[0].entity_id,
      "destinationDomain": "https://"+dnsEndpoint,
      "ip": "",
      "payload" :{entity_id:val?val.entity_id:portalList[0].entity_id},
      "calltype":"logon"
    }

    ssoRedirection(ssoparam);
    /*
    if (portalList.length === 1) {
      window.open("https://" + portalList[0].dns_endpoint + "/auth?id_token=" + localStorage.getItem(AUTH_USER_TOKEN_KEY) + "&returnUrl=/", '_blank');
    } else {
      window.open("https://" + val.dns_endpoint + "/auth?id_token=" + localStorage.getItem(AUTH_USER_TOKEN_KEY) + "&returnUrl=/", '_blank');
    }*/
  }
  const goToKonnexsion = () => {
    // var ssoparam = {
    //   "entityId":"",
    //   "destinationDomain": process.env.REACT_APP_KPM_URL,
    //   "ip": "",
    //   "payload" :{},
    //   "calltype":""
    // }
    // ssoRedirection(ssoparam);
    window.open(process.env.REACT_APP_KPM_URL as any+"/auth?id_token=" + localStorage.getItem(AUTH_USER_TOKEN_KEY), '_blank');
  }

  const triggerMenuAction = (menuItem:any)=>{
      if(menuItem && menuItem.action){
         let app = menuItem.action.app || (menuItem.action.payload && menuItem.action.payload.site);
         let land = menuItem.action.land?menuItem.action.land:"";
         let ssoParam:any = {
              // destinationDomain:"",
              // ip:"",
              userId:props.userId,
				      entityId:selectedentity?selectedentity.entity_id:null,
              /*payload:{
                // user_id:props.userId,
                // entity_id:selectedentity?selectedentity.entity_id:null,
                email_id:localStorage.getItem("verified_email")?localStorage.getItem("verified_email"):"",
                land:land,
                // return_url:"",
                // dateStr:"",
                app:app
              },*/
              //payload:(menuItem.action.type === 'link')?{...menuItem.action.payload}:{...menuItem.action},
              calltype:"logon"
          };

          if(menuItem.action.type === 'link'){
            ssoParam = {...ssoParam,...menuItem.action.payload};
          } else {
            ssoParam.payload = {...menuItem.action};
          }
         
          ssoRedirection(ssoParam,app,true);
          /*
          if(app === "app2"){
              // let REPORTING_DOMAIN = process.env.REACT_APP_REPORTING_URL as any || "";
              // ssoParam.destinationDomain = REPORTING_DOMAIN;
              ssoRedirection(ssoParam,app,true);
          } else if(app === "portal"){
            ssoParam.payload.return_url = "/";
            ssoParam.destinationDomain = (entitysitelist && entitysitelist.length>0)?"https://"+entitysitelist[0].dns_endpoint:"";
            ssoRedirection(ssoParam,app,true);
          } else if(app === "website"){
            let entitysite  = (entitysitelist && entitysitelist.length>0)?"https://"+entitysitelist[0].dns_endpoint:"";
            if(entitysite){
              window.open(entitysite, '_blank');
            }
          } else if(app === "app"){
            ssoParam.destinationDomain = process.env.REACT_APP_KPM_URL||"";
            ssoRedirection(ssoParam,app,true);
          } else if(app === "forum"){
              window.open(process.env.REACT_APP_FORUM_URL as any, '_blank');
          }
          */
      }
  }

  return ( 
    
    <nav className="page-sidebar" id="sidebar">
      <div id="sidebar-collapse">
        <ul className="side-menu metismenu">
          <li>
            <a href="javascript:;" onClick={() => props.onToggle('profile', 'Personal Details')}><span className="nav-label">Personal Details</span></a>
          </li>
          <li>
            <a href="javascript:;" onClick={() => props.onToggle('login', 'Security Credentials')}><span className="nav-label">Security Credentials</span></a>
          </li>
          <li>
            <a href="javascript:;" onClick={() => props.onToggle('authlog', 'Login History')}><span className="nav-label">Login History</span></a>
          </li>
          <li>
            <a href="javascript:;" onClick={() => props.onToggle('device', 'Device History')}><span className="nav-label">Device History</span></a>
          </li>
          <li>
            <a href="javascript:;" onClick={() => props.onToggle('subscription', 'Subscriptions')}><span className="nav-label">Subscriptions</span></a>
          </li>

          <li title='Entity'>
              <Select
                options={entitylistdata}
                getOptionLabel={(option: any) => option.entity_name}
                getOptionValue={(option: any) => option.entity_id}
                onChange={(data: any) => fetchdynamicmenuitem(data.entity_id,true)}
                value={selectedentity}
                maxMenuHeight={150}
                placeholder={"Select Entity"}
                className='sidebarEntitySelector'
              />
          </li>

          {menulist && menulist.length > 0 &&
            <ul className="listItemMaxHeight leftMenuList">
              {sortBy(menulist,"order").map((val:any, i:any) => (
                <li key={i}>
                    <a href="javascript:;" className="gotokonnexsionlink"  onClick={()=> triggerMenuAction(val)}>
                        <span className="nav-label"> {val.name}</span>
                    </a>
                </li>
              ))
              }
            </ul>
          }
          {/* { validKPMEntities && validKPMEntities.length>0 && (
          <li className="gotokonnexsionlinkTop">
            <a href="javascript:;" className="gotokonnexsionlink"  onClick={()=> goToKonnexsion()}>
              <span className="nav-label"> Go to Konnexsion</span></a>
          </li>)
          } */}

          {/* {portalList.length > 0 &&
            <li>
              {portalList.length < 2 && (
              <a href="javascript:;" className="gotokonnexsionlink" onClick={()=> goToPortal()}>
                <span className="nav-label"> Go to Portal</span>
              </a>
              )}

              {portalList.length > 1 && (             
                <span className="nav-label sideBarLabel"> Go to Portal</span>             
              )}

              {portalList.length > 1 &&
                <ul className="listItemMaxHeight">
                  {portalList.map((val:any, i:any) => (
                    <li key={i}>
                    <a href="javascript:;" className="gotokonnexsionlink"  onClick={()=> goToPortal(val)}>
                  <span className="nav-label"> {val.dns_endpoint}</span>
                    </a>
                    </li>
                  ))
                  }
                </ul>
              }
            </li>
          } */}
        </ul>
      </div>
    </nav>
  )
}

SideBar.propTypes = {
  onToggle: PropTypes.func,
  userId: PropTypes.any
}

export default SideBar;