import React from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import { AUTH_USER_TOKEN_KEY } from '../../Utils/constants';

const NavBar = () => {
  const history = useHistory();
  async function signOut() {
    try {
        await Auth.signOut({ global: true });
        // localStorage.clear(); -- Preserve Device Tracking
        localStorage.removeItem(AUTH_USER_TOKEN_KEY);
        localStorage.removeItem('verified_email');
        localStorage.removeItem('verified_phone');
        history.push("/login");
    } catch (error) {
        console.log('error signing out: ', error);
    }
}

const toggleSidebar=() =>{
  if (document.body.classList.contains("sidebarOpen")) {
    document.body.classList.remove("sidebarOpen")
    } else {
    document.body.classList.add("sidebarOpen")
    }
  


}
  return (
    <header className="header">
      <div className="flexbox flex-1">
        <ul className="nav navbar-toolbar">
          <li>
          <img src={require('../../assets/images/konnexsion-logo-master.png')} alt="" />
          </li>
          {/* <li className="f-l-name">First Name Last Name – User Profile</li> */}
        </ul>
        <ul className="nav navbar-toolbar toolbarRight">
          <li>
          <button className="logoutAction" onClick={()=>signOut()}> {/* <i className="la la-sign-out" aria-hidden="true"></i>  */}<span>Logout</span> </button>
          
          </li></ul>
       

      </div>

      <div className="menuToggleMobile" >  <button onClick={()=>toggleSidebar()} ><i className="la la-bars" aria-hidden="true"></i></button></div> 
    </header>
  )
}

export default NavBar;