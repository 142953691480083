import React from 'react';
import PropTypes from 'prop-types';

const AuthLog = (props: any) => {
  return (
    <div className="row">
    <div className="col-sm-12">
      <table className="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Event</th>
            <th>Result</th>
            <th>Risk Level</th>
            <th>Challenge</th>
            <th>IP</th>
            <th>Devics</th>
            <th>Location</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            props.authEvents && props.authEvents.map((authEvt: any, i: any) => (
              <tr key={`authLog_${i}`}>
                <td>{authEvt.creationDate.toString()}</td>
                <td>{authEvt.eventType}</td>
                <td>{authEvt.eventResponse}</td>
                <td>{authEvt.eventRisk.riskDecision}</td>
                <td>{authEvt.challengeResponses.length ? authEvt.challengeResponses[0].challengeName : ""}</td>
                <td>{authEvt.eventContextData.ipAddress}</td>
                <td>{authEvt.eventContextData.deviceName}</td>
                <td>{authEvt.eventContextData.city ? authEvt.eventContextData.city : authEvt.eventContextData.country}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
 </div>
 )
}

AuthLog.propTypes = {
  authEvents: PropTypes.array
}

export default AuthLog;