import React, { useEffect, useRef } from "react";
import PropTypes from 'prop-types';

const GPlace = (props: any) => {
  const placeInputRef = useRef(null);

  useEffect(() => {
    initPlaceAPI();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // initialize the google place autocomplete
  const initPlaceAPI = () => {
    let autocomplete = new (window as any).google.maps.places.Autocomplete(placeInputRef.current);
    new (window as any).google.maps.event.addListener(autocomplete, "place_changed", function () {
      let place = autocomplete.getPlace();
      props.placeChanged(place);
    });
  };

  return (
      <input type="text" ref={placeInputRef} />
  );
};

GPlace.propTypes = {
  placeChanged: PropTypes.func,
}

export default GPlace;