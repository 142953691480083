import React from 'react';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

const AddPhoneNumber = (props:any) => {
  return (
    <React.Fragment>
      <PhoneInput
        placeholder={props.placeholder}
        defaultCountry={props.defaultCountry}
        value={props.value}
        onChange={(data: any) => props.onChange(data)}
      />
      {props.value && !isValidPhoneNumber(props.value) && (<p>Invalid Number</p>)}
    </React.Fragment>
  )
}

AddPhoneNumber.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  defaultCountry: PropTypes.string
}

export default AddPhoneNumber;