import * as React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

/** Container components */
import DashBoardContainer from '../Containers/DashboardContainer';
import LoginContainer from '../Containers/LoginContainer';
import SignUpContainer from '../Containers/SignUpContainer';
import ConfirmEmailContainer from '../Containers/ConfirmEmailContainer';
import ForgotPasswordContainer from '../Containers/ForgotPasswordContainer';
import PasswordResetContainer from '../Containers/PasswordResetContainer';
import ProfileContainer from '../Containers/ProfileContainer';
import NavBar from '../Components/common/NavBar';

/** private route component */
import PrivateRoute from './PrivateRoute';

/**
 * This is the central routing component for all the available routing.
 * A custom made Private router component is used for secure routes.
 * @component 
 */
const AppRouter = () => {
  const currentYear = new Date().getFullYear();
  const licenceAgreementUrl = process.env.REACT_APP_LICENCE_URL || "https://legal.kpm.one/eula";
  return (
    <Router>
      <React.Fragment>
        <Switch>
          <Route exact={true} path="/">
            <Redirect to="/profile"/>
          </Route>
          <Route exact={true} path="/login" component={LoginContainer} />
          <Route exact={true} path="/signup" component={SignUpContainer} />
          <Route exact={true} path="/verify-code" component={ConfirmEmailContainer} />
          <Route exact={true} path="/reset-password" component={PasswordResetContainer} />
          <Route exact={true} path="/forgot-password" component={ForgotPasswordContainer} />
          <div className="page-wrapper">
            <NavBar/>
            <div className="content-wrapper">
              <PrivateRoute exact={true} path="/dashboard" component={DashBoardContainer} />
              <PrivateRoute exact={true} path="/profile" component={ProfileContainer} />
              <div className="footerCopyRight">
                © Copyright {currentYear} Konnexsion Property Ltd. All rights reserved. <a className="footerliclink" href={licenceAgreementUrl} target="_blank"> License Agreement</a> 
              </div>
            </div>
          </div>
        </Switch>
      </React.Fragment>
    </Router>
  );
}

export default AppRouter;
