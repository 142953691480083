import AWS from 'aws-sdk';
import CognitoIdentityServiceProvider from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { poolCongig, awsConfig } from '../constants';
import CommonService from '../Services/CommonService';
import { AUTH_USER_TOKEN_KEY } from '../Utils/constants';
const jwtDecode = require('jwt-decode');

/**
 * helper method to validate  user token
 *
 * @param {*} token
 * @returns {boolean}
 */
export const validateToken = (token: any): boolean => {
  if (!token) {
    return false;
  }
  try {
    const decodedJwt: any = jwtDecode(token);
    return decodedJwt.exp >= Date.now() / 1000;
  } catch (e) {
    return false;
  }
};

export const isRevoked = (accessToken:any) => {
  return new Promise((resolve) => {
    /* AWS.config.correctClockSkew = true;
    AWS.config.region = poolCongig.region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: poolCongig.userPoolId!
    });
    AWS.config.update({
      accessKeyId: awsConfig.accessKeyId,
      secretAccessKey: awsConfig.secretAccessKey
    }); */
    let params:any = {
      AccessToken: accessToken
    };
    params.type = 'user';
    /* new CognitoIdentityServiceProvider().getUser(params, function(err, data) {
      if (err) {
        resolve(true)
      } else {
        resolve(false);
      }
    }); */
    CommonService.portalApiCall("manageuser", params, localStorage.getItem(AUTH_USER_TOKEN_KEY)
    ).then(response => {
      if (response.data.errorCode === 0) {
        resolve(false);
      } else {
        resolve(true);
      }
    },
      err => {
        resolve(true);
      });
  });
}
