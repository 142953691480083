import React from 'react';
import PropTypes from 'prop-types';

const DeviceLog = (props: any) => {
  return (
    <div className="row">
    <div className="col-sm-12">
      <table className="table">
        <thead>
          <tr>
            <th>Device Key</th>
            <th>Name</th>
            <th>Last IP</th>
            <th>Remembered</th>
            <th>Last Seen</th>
          </tr>
        </thead>
        <tbody>
          {
            props.deviceList && props.deviceList.map((device: any, i: any) => (
              <tr key={`device_${i}`}>
                <td>
                  {device.deviceKey}
                </td>
                <td>
                  {props.translateAttributes(device.deviceAttributes, 'device_name')}
                </td>
                <td>
                  {props.translateAttributes(device.deviceAttributes, 'last_ip_used')}
                </td>
                <td>
                  {props.translateAttributes(device.deviceAttributes, 'dev:device_remembered_status')}
                </td>
                <td>{device.deviceLastModifiedDate.toString()}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
    </div>
  )
}

DeviceLog.propTypes = {
  deviceList: PropTypes.array,
  translateAttributes: PropTypes.func
}

export default DeviceLog;