export const poolCongig = {
  // REQUIRED - Amazon Cognito Reg
  region: process.env.REACT_APP_COGNITO_REGION,

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,//'eu-west-2_IeKZW3WqG',

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,//'3dk18d2l390u8m7au77kj50qfk',

  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  mandatorySignIn: false,
  Storage: {
    bucket: 'kpm-dev-subscriber-doc-storage',
    region: process.env.REACT_APP_COGNITO_REGION
  },

  // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
  authenticationFlowType: 'USER_SRP_AUTH'
};

export const awsConfig= {
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_ACCESS_SECRET
};

export const gClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const subscriptiontermUrl = process.env.REACT_APP_SUBSCRIPTION_TERM_URL;
