import React, {useState} from 'react';
import PropTypes from 'prop-types';
import AddPhoneNumber from '../../../Directives/AddPhoneNumber';
import { Auth } from 'aws-amplify';
import GoogleLogin from 'react-google-login';
import QRCode from 'qrcode.react';
import { Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { notification } from 'antd';
import { gClientId } from '../../../constants';

type User = {
  oldPassword: string;
  newPassword: string;
};

const LoginDetail = (props: any) => {
  const { register, handleSubmit, errors, /* formState */ } = useForm<User>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const verifyMethod = (key: string) => {
    if (key === 'phone_number') {
      props.verifyCognitoAttribute('phone_number');
    } else {
      props.verifyCognitoAttribute('email')
    }
  }
  const confirmVerify = () => {
    if (props.currentVerify === 'phone_number') {
      props.confirmCognitoAttribute('phone_number', props.otp)
    } else {
      props.confirmCognitoAttribute('email', props.otp)
    }
  }
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleCloseVerify = () => props.closeVerifyPop();
  const handleShow = () => setShow(true);
  const onSubmit = (event: User) => {
    setIsSubmitting(true);
    let { oldPassword, newPassword } = event;
    Auth.currentAuthenticatedUser()
    .then(user => {
        return Auth.changePassword(user, oldPassword, newPassword);
    })
    .then(data => {
      console.log(data);
      setIsSubmitting(false);
      handleClose();
      notification.success({
        message: 'Succes',
        description: 'Password Updated Successfully',
        placement: 'topRight',
        duration: 1.5
      })
    })
    .catch(err => {
      console.log(err);
      setIsSubmitting(false);
      notification.error({
        message: 'Error',
        description: err.message,
        placement: 'topRight'
      });
    });
  }

  const hideBarCode = () => {
    if (props.profile["custom:mfasettings"] && JSON.parse(props.profile["custom:mfasettings"]).devices.length) {
      return "Y";
    }
    return "N";
  }
  return (
    <React.Fragment>
      <div className="row profileGroupContain">
        <div className="col-sm-8 col-lg-6">
          <div className="form-group frm-group-row row emailBottomRow">
            <div className="col-sm-3 login-col-with ">
              <label className="labeltopalign">Login Email</label>
            </div>
            <div className="col-sm-9">
              <div className="inputEditWrap">
                {!props.isEmailEdit && <div className="inputvalue-label">{props.profile.email}</div>}
                {props.isEmailEdit && <div>
                  <input
                    type="email"
                    className="form-control"
                    id="inputCEmail"
                    placeholder="Email"
                    defaultValue={props.profile ? props.profile.email : ""}
                    onChange={(data: any) => props.handleInputChange('email', data.target.value)}
                  />
                </div>}
              </div>
              <div className="verifyContainWrapper">
                {!props.isEmailEdit &&
                  <span className="pull-left">
                    <button className="editandverifyButton" onClick={() => props.toggleTextView('email')}>
                      <i className="la la-pencil text-blue " aria-hidden="true"></i>
                    </button>
                  </span>
                }
                {props.isEmailEdit &&
                  <span className="pull-left">
                    <button className="editandverifyButton" onClick={() => props.saveFunc('email')}>
                      <i className="fa fa-check  text-green" aria-hidden="true"></i>
                    </button>
                  </span>
                }
                {/* <span className="pull-left">
                  {!props.profile.email_verified &&
                    <button className="verifyBtnLogin" onClick={() => verifyMethod('email')}>
                      {props.verifyEmailLoading && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                  Verify
                </button>}
                </span> */}


                {props.profile.email_verified && !props.isEmailEdit && <span className="text-green pull-left verandnotver">Verified</span>}


              </div>

            </div>

          </div>
          <div className="form-group row">
            <div className="col-sm-3 login-col-with">
              <label className="labeltopalign">Mobile Number</label>
            </div>
            <div className="col-sm-8">
              <div className="inputEditWrap">
                {!props.isPhoneEdit && <div className="inputvalue-label">{props.profile.phone_number}</div>}
                {props.isPhoneEdit && <AddPhoneNumber
                  placeholder="Enter phone number"
                  defaultCountry='GB'
                  value={props.profile ? props.profile.phone_number : ""}
                  onChange={(data: any) => props.handleInputChange('phone_number', data)} />
                }
              </div>
              <div className="verifyContainWrapper">


                {!props.isPhoneEdit &&
                  <span className="pull-left">
                    <button className="editandverifyButton" onClick={() => props.toggleTextView('phone')}>
                      <i className="la la-pencil text-blue " aria-hidden="true"></i>
                    </button>
                  </span>
                }
                {props.isPhoneEdit &&
                  <span className="pull-left">
                    <button className="editandverifyButton" onClick={() => props.saveFunc('phone')}>
                      <i className="fa fa-check  text-green" aria-hidden="true"></i>
                    </button>
                  </span>
                }
                {props.profile.phone_number_verified && !props.isPhoneEdit && <span className="text-green pull-left verandnotver">Verified</span>}
                <span className="pull-left">
                  {!props.profile.phone_number_verified && props.profile.phone_number !== '' && props.profile.phone_number !== null &&
                    <button className="verifyBtnLogin" onClick={() => verifyMethod('phone_number')}>
                      {props.verifyLoading && props.currentVerify === 'phone_number' && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                  Verify
                </button>}
                  {/* {props.openVerifyPopup && (
                    <div className="profile-inner">
                      <div className="form-group row">
                        You’re current {props.currentVerify === 'email' ? "email" : "mobile number"} will continue to be in effect until the new {props.currentVerify === 'email' ? "email" : "mobile"} has been verified.
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-4 text-right">
                          <label className="verificationCode">Verification Code</label>
                        </div>
                        <div className="col-sm-6">
                          <input className="form-control"
                            type="text"
                            maxLength={8}
                            onChange={(data) => props.handleVerificationCode(data.target.value)}
                          />
                        </div>
                        <div className="col-sm-2">
                          <button className="btn btn-primary " onClick={() => confirmVerify()}>
                            {props.verifyLoading && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                        Submit
                      </button>
                        </div>
                      </div>

                    </div>
                  )} */}
                  <Modal show={props.openVerifyPopup} onHide={handleCloseVerify} dialogClassName="modal-dialog-centered">
                    <Modal.Header closeButton>
                      <Modal.Title>
                      Verify your {props.currentVerify === 'email' ? "email" : "phone"}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-sm-12 ">

                          <div className="verificationWrapper ">
                          {/* <div className="profile-inner"> */}
                            <div className=" row">
                              <div className="col-sm-12"> 
                              <p className="currentVerifyTx">

                              <span className="pull-left phoneVInfoIconLeft"><i className="la la-info-circle" aria-hidden="true"></i></span> 
                              <span className="pull-left phoneVInfoTxLeft">  Your current {props.currentVerify === 'email' ? "email" : "mobile number"} will continue to be in effect until the new {props.currentVerify === 'email' ? "email" : "mobile"} has been verified.</span>
                              
                             

                              </p>
                              </div>



                             
                            </div>

                            <div className="row">

                            <div className="col verificationCodeCol text-right">
                                <label className="verificationCode">Verification Code</label>
                              </div>
                              <div className="col">
                                <input className="form-control"
                                  type="text"
                                  maxLength={8}
                                  onChange={(data) => props.handleVerificationCode(data.target.value)}
                                />
                              </div>
                              <div className="col">
                                <button className="btn btn-primary " onClick={() => confirmVerify()}>
                                  {props.verifyLoading && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                        Submit
                      </button>
                              </div>
                            </div>

                          {/* </div> */}
                          </div>

                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </span>
                {/*  <span className="text-red pull-left verandnotver">Not verified</span>


                   */}
              </div>
            </div>

          </div>
        </div>
        <div className="col-sm-12 m-b-20"> <button className="btn btn-primary updatePasword" onClick={handleShow}> Update Password</button></div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update Password</Modal.Title>
          </Modal.Header> 
          <Modal.Body>
            <div className="row">
              <div className="col-sm-12 ">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group ">
                    <label>Old Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter password"
                      name="oldPassword"
                      ref={register({
                        required: true,
                        minLength: 6,
                      })}
                    />
                    {errors.oldPassword && errors.oldPassword.type === "required" && (
                      <div className="error">Your must enter your password.</div>
                    )}
                  </div>
                  <div className="form-group password-form">
                    <label>New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter password"
                      name="newPassword"
                      ref={register({
                        required: true,
                        minLength: 6,
                      })}
                    />
                    {errors.newPassword && errors.newPassword.type === "required" && (
                      <div className="error">Your must enter your password.</div>
                    )}
                  </div>
                  <button type="submit" className="btn btn-primary btn-block btn-login" disabled={isSubmitting}>
                    {isSubmitting && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                Submit
              </button>
                </form>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
          </Button>
          </Modal.Footer> */}
        </Modal>
      </div>
      <div className="row profileGroupContain">
        <div className="col-sm-6">
          <div className="form-group row">
            <div className="col-sm-3"><label >MFA Settings</label></div>
            <div className="col-sm-9">
              <table className="mfaSettings">
                <tr>
                  <th>MFA</th>
                  <th>Enabled</th>
                  <th>Preferred</th>
                </tr>
                <tr>
                  <td>Authenticator App</td>
                  <td> <label>
                    <input type="checkbox"
                      value="SOFTWARE_TOKEN_MFA"
                      disabled={!(props.profile["custom:mfasettings"]) || 
                      (props.profile["custom:mfasettings"] && JSON.parse(props.profile["custom:mfasettings"]).devices.length == 0)}
                      defaultChecked={props.UserMFASettingList.indexOf('SOFTWARE_TOKEN_MFA') > -1}
                      onChange={() => props.mfaChange(props.UserMFASettingList.indexOf('SOFTWARE_TOKEN_MFA') > -1 ? '' : 'SOFTWARE_TOKEN_MFA', 'SOFTWARE_TOKEN_MFA')}
                    />
                    {/* <i>Requires Setup</i> */} </label>
                  </td>
                  <td> <label>
                    <input type="radio"
                      value="SOFTWARE_TOKEN_MFA"
                      disabled={props.UserMFASettingList.indexOf('SOFTWARE_TOKEN_MFA') === -1}
                      checked={props.PreferredMfaSetting === 'SOFTWARE_TOKEN_MFA'}
                      onChange={() => props.mfaChange('SOFTWARE_TOKEN_MFA')}
                    />  </label>
                  </td>
                </tr>
                <tr>
                  <td>SMS</td>
                  <td><div className="radio"> <label>
                    <input type="checkbox"
                      value="SMS_MFA"
                      disabled={!(props.profile.phone_number && props.profile.phone_number_verified)}
                      defaultChecked={props.UserMFASettingList.indexOf('SMS_MFA') > -1}
                      onChange={() => props.mfaChange(props.UserMFASettingList.indexOf('SMS_MFA') > -1 ? '' : 'SMS_MFA', 'SMS_MFA')}
                    />
                    {/* <i> Requires Validation</i> */}  </label></div></td>
                  <td><label>
                    <input type="radio"
                      value="SMS_MFA"
                      disabled={props.UserMFASettingList.indexOf('SMS_MFA') === -1}
                      checked={props.PreferredMfaSetting === 'SMS_MFA'}
                      onChange={() => props.mfaChange('SMS_MFA')}
                    />
                  </label></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="row profileGroupContain"> 
        <div className="col-sm-9">
          <div className="row">
            <div className="col-sm-2"> <label className="labeltopalign">Add MFA Devices</label></div>
            <div className="col-sm-10 m-t-5">
              <button className="addDeviceBtn  addDeviceBtnStyle " onClick={() => props.addDevice()}> + <span>Add Device</span> </button>
              <button className=" resetDeviceBtn  addDeviceBtnStyle " onClick={() => props.addDevice(true)}>  <span> <i className="la la-refresh" aria-hidden="true"></i> Reset MFA</span> </button>
              {props.mfaUrl !== '' &&
                <table className="mfaSettings addmfaSettings"> 
                  <tr>
                    <th></th>

                      {props.showCode && <th>MFA code</th>}
                    <th>Device Name</th>
                    <th></th>

                  </tr>
                  <tr>
                    <td> {props.mfaUrl !== '' && <QRCode value={props.mfaUrl} />}</td>
 
                    {props.showCode &&  <td>
  
                      <input
                        type="text"
                        className="form-control"
                        id="inputmfa"
                        placeholder="Mfa code"
                        defaultValue={props.mfaCode}
                        onChange={(data: any) => props.handleMfaChange(data.target.value, 'code')}
                      />
                   
                    </td> }
                    <td>  <input
                      type="text"
                      className="form-control"
                      id="inputdevice"
                      defaultValue={props.deviceName}
                      onChange={(data: any) => props.handleMfaChange(data.target.value, 'device')}
                    /></td>

                    <td> <button className="btn btn-primary updatePasword" onClick={() => props.verifyDevice()}>
                      {props.showCode ? "Verify Device" : "Add Device"}</button></td>


                  </tr>



                </table>}
            </div>



          </div>


        </div>


      </div>


      <div className="row profileGroupContain">
        <div className="col-sm-6">
          <div className="row">

            <div className="col-sm-3"> <label className="labeltopalign"> Added Devices</label></div>
            <div className="col-sm-9">
              {props.profile["custom:mfasettings"] && (
                <ul className="device-profile-name">
                  {JSON.parse(props.profile["custom:mfasettings"]).devices.map((val: any, i: any) => (
                    <li key={`device_${i}`}><i className="fa fa-mobile" aria-hidden="true" /> {val}</li>
                  ))}
                </ul>
              )}
            </div>

          </div>
        </div>
      </div>
      <div className="row profileGroupContain">
        <div className="col-sm-6"> <span className="partyLogin ">3rd Party Login Providers</span>   
        <GoogleLogin className="googleLogin"
            clientId= {gClientId as string}
            buttonText=""
            onSuccess={props.socialCallBack}
            onFailure={props.socialCallBack}
            cookiePolicy={'single_host_origin'}
          /></div>
        
        <div className="col-sm-11 thirdLoginWrapper">
          <table className="thirdLoginGrid">
            <tbody>
              {
                props.socialUsers.length > 0 && props.socialUsers.map((profile: any, i: any) => (
                  <tr key={`socialtr_${i}`}>
                    <td>
                      <img src={require('../../../assets/images/google-icon.png')} className="googleIcon" alt="" />

                    </td>
                    <td>{profile.name}</td>
                    <td className="email-link-color">{profile.email}</td>
                    <td> </td>
                    <td className="text-right">
                      <button className="remove-action-btn" onClick={() => props.removeSocial(profile)}>
                        <i className="la la-trash" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>

      </div>

    </React.Fragment>
  )
}

LoginDetail.propTypes = {
  handleVerificationCode: PropTypes.func,
  confirmCognitoAttribute: PropTypes.func,
  verifyCognitoAttribute: PropTypes.func,
  handleInputChange: PropTypes.func,
  profile: PropTypes.object,
  openVerifyPopup: PropTypes.bool,
  otp: PropTypes.string,
  loading: PropTypes.bool,
  verifyLoading: PropTypes.bool,
  verifyEmailLoading: PropTypes.bool,
  socialCallBack: PropTypes.func,
  socialUsers: PropTypes.object,
  removeSocial: PropTypes.func,
  currentVerify: PropTypes.string,
  toggleTextView: PropTypes.func,
  isEmailEdit: PropTypes.bool,
  isPhoneEdit: PropTypes.bool,
  saveFunc: PropTypes.func,
  addDevice: PropTypes.func,
  mfaUrl: PropTypes.string,
  handleMfaChange: PropTypes.func,
  mfaCode: PropTypes.any,
  verifyDevice: PropTypes.func,
  deviceName: PropTypes.string,
  PreferredMfaSetting: PropTypes.string,
  UserMFASettingList: PropTypes.array,
  mfaChange: PropTypes.func,
  closeVerifyPop: PropTypes.func,
  showCode: PropTypes.bool
}

export default LoginDetail;