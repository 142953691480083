import React, {useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { notification } from 'antd';
import { Button, Modal } from 'react-bootstrap';

import CommonService from '../../../Services/CommonService';
import { AUTH_USER_TOKEN_KEY } from '../../../Utils/constants';
import SubscriptionForm from './SubscriptionForm';
import { useHistory } from 'react-router-dom';

const Subscription = (props: any) => {

  const history = useHistory();
  const [loadingData, setloadingData] = useState(true);
  const [showAddSubscription, setShowSubscription] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const handleShow = () => setShowSubscription(true);
  const handleClose = () => setShowSubscription(false);

  const getentitydetail = ()=>{
    setloadingData(true);
    CommonService.postApiExecutor("getentitydetail", {},localStorage.getItem(AUTH_USER_TOKEN_KEY)
      ).then(response => {
        setloadingData(false);
        if (response.data.errorCode === 1) {
          setSubscriptionData([]);
          notification.error({
            message: 'Error',
            description: response.data.description,
            placement: 'topRight'
          })
        } else {
          const data = response.data.customData;
          if(data && data.entity){
            setSubscriptionData(data.entity);
          }
        }
      },
      err => {
  
      })
  }

  const viewSubscription = (eid:any)=>{
    //process.env.REACT_APP_KPM_URL as any
    //https://app-aa1.kpmdev.one
    window.open(`https://${window.location.host}/login?site=kpm&returnUrl=accountsbilling&id=${eid}&name=subscription_info&returnPageName=Home`,"_blank");
  }

  const viewDomain = (eid:any)=>{
    //process.env.REACT_APP_REPORTING_URL as any
    window.open(`https://${window.location.host}/login?site=portal&host=`+(process.env.REACT_APP_REPORTING_URL as string).substr(8)+'&returnUrl='+encodeURIComponent(`/app/redirect?land=domainmanager&entity_id=${eid}`),"_blank"); 
    //window.open(`${process.env.REACT_APP_REPORTING_URL}/app/redirect?land=domainmanager&entity_id=${eid}`,"_blank");
  }

  const [subscriptionData,setSubscriptionData] = useState([]);
  useEffect(()=>{
    getentitydetail();
  },[]);

  return (
    <>
    <div className="row">
      <div className="col-sm-8">
        <button className="pull-left addSubBtn" onClick={handleShow}>Add Subscription</button>
      </div>
    </div>
    
    {subscriptionData.length>0 && 
      subscriptionData.map((subdata:any)=>
        (<div className="row subscriptionRow" key={subdata.id}>
        <div className="col-sm-9 content">        
          <h4>{subdata.name}  { subdata.isDefault && <i className="fa colorStar fa-star subStar " ></i>}
          { !subdata.isDefault && <i className="fa colorStar fa-star-o subStar " ></i>}</h4>
          <ul className="subscriptionAction">
            <li>{subdata.entityCategoryName}</li>
            <li>{subdata.entityDescription}</li>
            <li> <a href="javascript:void(0);" className="viewSubBtn" onClick={()=>viewSubscription(subdata.id)}>View Subscription</a></li>
           <li><a href="javascript:void(0);" className="viewSubBtn" style={{marginRight:'18px',marginTop:'6px'}} onClick={()=>viewDomain(subdata.id)}>Manage Domains</a></li>

          </ul>
        
        </div>
        </div>)
    )}

    {!loadingData && subscriptionData.length==0 && (
      <div className="row">
      <div className="col-sm-8">
        <h4>No Data Found</h4>
      </div>
      </div>
    )}

    <div className="row">
      <div className="col-sm-12">
        <Modal 
        show={showAddSubscription} 
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add new Subscription</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <SubscriptionForm 
            entityDetails={props.profileDetails} 
            agreedToTerms={agreedToTerms} 
            setAgreedToTerms={setAgreedToTerms}
            closeSubscriptionModal={handleClose}
            fetchSubscriptionList={getentitydetail}
            isLoginForm='N'
            location={null}
            />
          </Modal.Body>

          {/* <Modal.Footer>
            <Button variant="primary" onClick={handleClose} disabled={!agreedToTerms}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer> */}
        </Modal>
      </div>
    </div>
    </>
    
  )
}


export default Subscription;