import * as React from 'react';
import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import CognitoIdentityServiceProvider from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { notification } from 'antd';
import axios from 'axios';
import SideBar from './Pages/SideBar';
import LoginDetail from './Pages/LoginDetail';
import AuthLog from './Pages/AuthLog';
import DeviceLog from './Pages/DeviceLog';
import Subscription from './Pages/Subscription'
import Profile from './Pages/Profile';
import CommonService from '../../Services/CommonService';
import { AUTH_USER_TOKEN_KEY } from '../../Utils/constants';
import { isRevoked } from '../../Utils/Helpers';
import data from '../../data/contactTypes.json';
import { poolCongig, awsConfig } from '../../constants';

const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;//'AIzaSyChX1YUMKE9rFaDHkFho1I8fRWZXQ3GXTQ';//

class ProfileContainer extends React.Component {
  constructor(props: any) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKonInputChange = this.handleKonInputChange.bind(this);
    this.placeChanged = this.placeChanged.bind(this);
    this.loadProfile = this.loadProfile.bind(this);
    this.saveProfile = this.saveProfile.bind(this);
    this.phoneCahnge = this.phoneCahnge.bind(this);
    this.handleVerificationCode = this.handleVerificationCode.bind(this);
    this.toggleTextView = this.toggleTextView.bind(this);
    this.handleMfaChange  = this.handleMfaChange.bind(this);
    this.addDevice = this.addDevice.bind(this);
    this.verifyDevice = this.verifyDevice.bind(this);
    this.mfaChange = this.mfaChange.bind(this);
    this.state = {
      profile: null,
      konProfile: null,
      loading: false,
      phoneCounter: 1,
      phoneArray: [{contactId: 0, contactNumber: "", contactType: 16264}],
      kpmProfile: null,
      loadMap: false,
      placeObj: null,
      toggleState: 'profile',
      deviceList: null,
      authEvents: null,
      otp: '',
      openVerifyPopup: false,
      konUserId: 0,
      accessToken: null,
      countryCode: 'GB',
      photoObj: null,
      document: null,
      contactTypeDropDown: data,
      selectedType: {
        "detailId": 16264,
        "description": "Home",
        "category": "",
        "category2": "",
        "category3": "",
        "category4": "",
        "category5": "",
        "parentLookupDtlId": 0
      },
      cogUser: null,
      socialUsers: [],
      verifyLoading: false,
      verifyEmailLoading: false,
      currentVerify: 'phone_number',
      isEmailEdit: false,
      isPhoneEdit: false,
      tabName: 'Personal Details',
      mfaUrl: '',
      mfaCode: '',
      UserMFASettingList: [],
      PreferredMfaSetting: '',
      deviceName: '',
      shouldVerify: false,
      showCode: false
    };
  }

  componentDidMount() {
    const checkToken = async () => {
      const isRvkd = await isRevoked(localStorage.getItem(AUTH_USER_TOKEN_KEY));
      if (isRvkd) {
        //localStorage.clear(); Preserve Device Tracking
        localStorage.removeItem(AUTH_USER_TOKEN_KEY);
        localStorage.removeItem('verified_email');
        localStorage.removeItem('verified_phone');
 
        (this.props as any).history.push("/login");
      }
    }
    checkToken();
    //this.getGeoInfo();
    /* AWS.config.correctClockSkew = true;
    AWS.config.region = poolCongig.region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: poolCongig.userPoolId!
    });
    AWS.config.update({
      accessKeyId: awsConfig.accessKeyId,
      secretAccessKey: awsConfig.secretAccessKey
    }); */
    this.loadProfile();
    this.loadGoogleMapScript(() => {
      this.setState({loadMap: true});
    });
  }

 /*  getGeoInfo = () => {
    axios.get('https://ipapi.co/json/').then((response) => {
        let data = response.data;
        this.setState({
            countryCode: data.country_code
        });
    }).catch((error) => {
        console.log(error);
    });
 }; */

  loadGoogleMapScript = (callback:any) => {
    if (typeof (window as any).google === 'object' && typeof (window as any).google.maps === 'object') {
      callback();
    } else {
      const googleMapScript = document.createElement("script");
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
      window.document.body.appendChild(googleMapScript);
      googleMapScript.addEventListener("load", callback);
    }
  }

  handleInputChange(field: any, value: any) {
    const profile: any = Object.assign({}, (this.state as any).profile);
    profile[field] = value;
    this.setState({ profile: profile });
  }
  handleKonInputChange(field: any, value: any) {
    const profile: any = Object.assign({}, (this.state as any).konProfile);
    profile[field] = value;
    this.setState({ konProfile: profile });
    if (field === 'firstName') {
      this.handleInputChange('given_name', value);
    } else if (field === 'lastName') {
      this.handleInputChange('family_name', value)
    }
  }

  handleMfaChange(value:any, type:any) {
    if (type === 'code') {
      this.setState({ mfaCode: value });
    } else {
      this.setState({ deviceName: value });
    }
  }

  phoneCahnge(value:any, index:any) {
    if (value && value.length > 10) {
      const phnArr: any = Object.assign([], (this.state as any).phoneArray);
      phnArr[index] = {contactId: 0, contactNumber: value, contactType: 16264};
      this.setState({phoneArray: phnArr});
    }
  }

  handleDropDownChange(value:any, index:any) {

  }

  placeChanged(val:any) {
    console.log(val.geometry.location.lat());

    const country:any = val.address_components.find(
      (x:any) => x.types.indexOf("country") > -1
    );
    const county:any = val.address_components.find(
      (x:any) => x.types.indexOf("administrative_area_level_2") > -1
    );
    const street:any = val.address_components.find(
      (x:any) => x.types.indexOf("street_number") > -1
    );
    const route:any = val.address_components.find(
      (x:any) => x.types.indexOf("route") > -1
    );
    const city:any = val.address_components.find(
      (x:any) => x.types.indexOf("postal_town") > -1
    );
    const postalCode:any = val.address_components.find(
      (x:any) => x.types.indexOf("postal_code") > -1
    );
    let completeStreet = "";
    if (street) {
      completeStreet +=  street.long_name;
    }
    if (route) {
      completeStreet +=  street ? ` ${route.long_name}`: route.long_name;
    }
    const addressObj = {
      lattitude: val.geometry.location.lat(),
      longitude: val.geometry.location.lng(),
      streetName: completeStreet,
      city: city ? city.long_name : "",
      county: county ? county.long_name : "",
      postCode: postalCode ? postalCode.long_name : "",
      country: country ? country.long_name : "",
    }
    this.setState({placeObj: addressObj});
  }

  loadProfile(flag?:any) {
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then(user => 
      {
          if(!flag) {this.loadConnexsionProfile(user);}
          this.setState({ profile: user.attributes, cogUser: user,
            konUserId: user.attributes["custom:kpmUserId"], 
            accessToken: user.signInUserSession.accessToken.jwtToken
          });
          localStorage.setItem("verified_email", user.attributes.email);
          if (user.attributes.phone_number_verified) {
            localStorage.setItem("verified_phone", user.attributes.phone_number);
          } else {
            //localStorage.removeItem("verified_phone")
          }
          if (user.attributes["custom:mfasettings"] && JSON.parse(user.attributes["custom:mfasettings"]).devices.length) {
            this.setState({showCode: false});
          } else {
            this.setState({showCode: true});
          }
            if (!flag) {
              if (user.attributes["custom:socialIdentities"]) {
                this.setState({socialUsers: JSON.parse(user.attributes["custom:socialIdentities"])})
              } else {
                this.setState({socialUsers: []})
              }
            }
          this.loadMFA(user);
          var that = this;
          /* user.listDevices(null, null, {
            onSuccess: function (result:any) {
              that.setState({deviceList: result.Devices});
            },
            onFailure: function (err:any) {
              alert(err.message);
            }
          }); */
          var params1:any = {
            UserPoolId: user.pool.userPoolId, 
            Username: user.username,
            "Limit": null as any,
            "PaginationToken": null as any
          };
         /*  new CognitoIdentityServiceProvider().adminListDevices(params1, function(err, data) {
            if (err) console.log(err, err.stack); 
            else   {
              that.setState({deviceList: data.Devices});
            }  
          }); */
          params1.type = 'ALD';
          CommonService.portalApiCall("manageuser", params1, localStorage.getItem(AUTH_USER_TOKEN_KEY)
          ).then((response:any) => {
            if (response.data.errorCode === 0) {
              that.setState({deviceList: response.data.customData.devices});
            }  else {
              (this.props as any).history.push("/login");
            }
          },
          err => {
    
          })
          let params:any = {
            UserPoolId: user.pool.userPoolId, 
            Username: user.username,
            MaxResults: null as any,
            NextToken: null as any
          };
          params.type='ALUAE';
          /* new CognitoIdentityServiceProvider().adminListUserAuthEvents(params, function(err, data) {
            if (err) console.log(err, err.stack); 
            else   {
              that.setState({authEvents: data.AuthEvents})
            }  
          }); */
        CommonService.portalApiCall("manageuser", params, localStorage.getItem(AUTH_USER_TOKEN_KEY)
        ).then(response => {
          if (response.data.errorCode === 0) {
            that.setState({ authEvents: response.data.customData.authEvents })
          }
        },
          err => {

          });
      })
      .catch(err => {
        this.setState({ user: null });
        //localStorage.clear(); Preserve Device Tracking
        localStorage.removeItem(AUTH_USER_TOKEN_KEY);
        localStorage.removeItem('verified_email');
        localStorage.removeItem('verified_phone');
        (this.props as any).history.push("/login");
      });
  }

  loadMFA(user:any) {
    var that = this;
    const  token:any = {"AccessToken": localStorage.getItem(AUTH_USER_TOKEN_KEY)};
    token.type = 'user';
    /* new CognitoIdentityServiceProvider().getUser(token, function (err, data) {
      if (err) console.log(err, err.stack);
      else {
        console.log(data);
        if (data.UserMFASettingList && data.UserMFASettingList.length > 0) {
          that.setState({ UserMFASettingList: data.UserMFASettingList, PreferredMfaSetting: data.PreferredMfaSetting });
        } else {
          that.setState({PreferredMfaSetting: 'DISABLED'})
        }
      }
    }); */
    CommonService.portalApiCall("manageuser", token, localStorage.getItem(AUTH_USER_TOKEN_KEY)
    ).then(response => {
      if (response.data.errorCode === 0) {
        if (response.data.customData.userMFASettingList && response.data.customData.userMFASettingList.length > 0) {
          that.setState({ UserMFASettingList: response.data.customData.userMFASettingList, PreferredMfaSetting: response.data.customData.preferredMfaSetting });
        } else {
          that.setState({PreferredMfaSetting: 'DISABLED'})
        }
      }
    },
      err => {

      });

  }

  socialCallBack = (response: any) => {
    let params:any = {
      "DestinationUser": {
        "ProviderAttributeValue": (this.state as any).cogUser.username,
        "ProviderName": "Cognito"
      },
      "SourceUser": {
        "ProviderAttributeName": "Cognito_Subject",
        "ProviderAttributeValue": response.googleId,
        "ProviderName": "Google"
      },
      "UserPoolId": poolCongig.userPoolId!
    };
    params.type = 'ALPU';
    var that = this;
    CommonService.portalApiCall("manageuser", params, localStorage.getItem(AUTH_USER_TOKEN_KEY)
    ).then(resp => {
      if(resp.data.errorCode === 1){
        notification.error({
          message: 'Error',
          description: resp.data.description,
          placement: 'topRight'
        })
        return;
      }
      const profileS: any = Object.assign([], (that.state as any).socialUsers);
        profileS.push({
          id: response.googleId,
          provider: "Google",
          email: response.profileObj.email,
          name: response.profileObj.name
        });
        that.setState({ socialUsers: profileS })
        const profile: any = Object.assign({}, (that.state as any).profile);
        profile["custom:socialIdentities"] = JSON.stringify(profileS);
        delete profile["identities"];
        Auth.updateUserAttributes((that.state as any).cogUser, profile)
          .then(data => {
            notification.success({
              message: 'Succes',
              description: 'Profile Linked Successfully',
              placement: 'topRight',
              duration: 1.5
            })
          })
          .catch(err => notification.error({
            message: 'Error',
            description: err.message || err,
            placement: 'topRight'
          }));
    },
      err => {

      });

  }

  removeSocial = (response: any) => {
    let params:any = {
      "User": {
        "ProviderAttributeName": "Cognito_Subject",
        "ProviderAttributeValue": response.id,
        "ProviderName": "Google"
      },
      "UserPoolId": poolCongig.userPoolId!
    }
    params.type='ADPU';
    var that = this;
    CommonService.portalApiCall("manageuser", params, localStorage.getItem(AUTH_USER_TOKEN_KEY)
    ).then(resp => {
      const profileS: any = Object.assign([], (that.state as any).socialUsers);
      that.setState({ socialUsers: profileS.filter((x: any) => x.id !== response.id) })
      const profile: any = Object.assign({}, (that.state as any).profile);
      profile["custom:socialIdentities"] = JSON.stringify(profileS.filter((x: any) => x.id !== response.id));
      delete profile["identities"];
      Auth.updateUserAttributes((that.state as any).cogUser, profile)
        .then(data => {
          notification.success({
            message: 'Succes',
            description: 'Link Removed Successfully',
            placement: 'topRight',
            duration: 1.5
          })
        })
        .catch(err => notification.error({
          message: 'Error',
          description: err.message || err,
          placement: 'topRight'
        }));
    },
      err => {

      });
  }

  loadConnexsionProfile(user:any) {
    CommonService.postApiExecutor("user/getuserdetails", 
    {userId: user.attributes["custom:kpmUserId"]}, 
    user.signInUserSession.accessToken.jwtToken).then(response => {
      if (response.data.errorCode !== 0) {

      } else {
        //console.log(response.data.customData);
        const addressObj = {
          "lattitude": response.data.customData.lattitude,
          "longitude": response.data.customData.longitude,
          //"lookUpAddress": response.data.customData.,
          "streetName": response.data.customData.streetName,
          "county": response.data.customData.county,
          "city": response.data.customData.city,
          "country": response.data.customData.country === 1 ? "United Kingdom" : response.data.customData.country,
          "postCode": response.data.customData.postcode
        }
        
        this.setState({konProfile: response.data.customData, placeObj: addressObj});
        if (response.data.customData.contactList && response.data.customData.contactList.length) {
          this.setState({
            phoneCounter: response.data.customData.contactList.length, 
            phoneArray: response.data.customData.contactList
          });
        }
      }
    })
  }

  async saveProfile(key?:any) {
    const user: any = await Auth.currentAuthenticatedUser();
    if (!user) {
      notification.error({
        message: 'Error',
        description: 'No user to save',
        placement: 'topRight'
      });
      return;
    }
    const profile: any = Object.assign({}, (this.state as any).profile);
    if(!profile.given_name) {
      profile.given_name = (this.state as any).konProfile.firstName;
    }
    if(!profile.family_name) {
      profile.family_name = (this.state as any).konProfile.lastName;
    }
    delete profile["identities"];
    Auth.updateUserAttributes(user, profile)
      .then(data => {
        if (key === 'email') {
          this.setState({isEmailEdit: false});
          localStorage.setItem("email_to_verify", profile['email']);
          this.verifyMethod('email');
        } else if (key === 'phone'){
          this.setState({isPhoneEdit: false});
          if (localStorage.getItem("verified_phone")) {
            localStorage.setItem("phone_to_verify", profile['phone_number']);
            this.verifyMethod('phone');
          }
        }
        if (key == "none") {

        } else {
          this.loadProfile(true);
        }
        
        if (key !== 'qr') {notification.success({
          message: 'Succes',
          description: 'Profile Updated Successfully',
          placement: 'topRight',
          duration: 1.5
        })}
        this.setState({mfaUrl: '', mfaCode: '', deviceName: ''})
      })
      .catch(err => notification.error({
        message: 'Error',
        description: err.message || err,
        placement: 'topRight'
      }));
  }

  saveKonProfile () {
    this.setState({loading: true});
    let contactArr = (this.state as any).phoneArray;
    if (contactArr.length === 1 && (contactArr[0].contactNumber == null || contactArr[0].contactNumber === "")) {
      contactArr = [];
    }
    const saveParams = {
      "userId": (this.state as any).konUserId,
      //"entityId": 1367,
      "title": (this.state as any).konProfile.title,
      "firstName": (this.state as any).konProfile.firstName,
      "middleName": (this.state as any).konProfile.middleName,
      "lastName": (this.state as any).konProfile.lastName,
      "passportNumber": null,
      "userStatus": 1,
      "address": Object.assign({}, (this.state as any).placeObj),
      "document": [
        
      ],
      "contacts": contactArr
    };
    if ((this.state as any).document) {
      saveParams.document = (this.state as any).document;
    }
    saveParams.address.country = 1;
    const token = (this.state as any).accessToken
    CommonService.postApiExecutor("user/updateuserprofile", saveParams, token
    ).then(response => {
      this.saveProfile();
      this.setState({loading: false});
      if (response.data.errorCode === 1) {
        notification.error({
          message: 'Error',
          description: response.data.description,
          placement: 'topRight'
        })
      } else {
        notification.success({
          message: 'Succes',
          description: 'Profile Updated Successfully',
          placement: 'topRight',
          duration: 1.5
        })
      }
    });

  }

  addnewPhone = (e:any) => {
    e.preventDefault();
    const phnArr: any = Object.assign([], (this.state as any).phoneArray);
    phnArr.push({contactId: 0, contactNumber: "", contactType: 16264});
    let currentCounter = (this.state as any).phoneCounter;
    this.setState({phoneCounter: currentCounter+1, phoneArray: phnArr});
  }
  removePhone = (e:any, index:any) => {
    e.preventDefault();
    const phnArr: any = Object.assign([], (this.state as any).phoneArray);
    const newArr = phnArr.slice(0, index).concat(phnArr.slice(index + 1, phnArr.length));
    let currentCounter = (this.state as any).phoneCounter;
    this.setState({phoneCounter: currentCounter-1, phoneArray: newArr});
  }

  doToggleState = (val:string, tab:string) => {
    this.setState({toggleState: val, tabName: tab});
  }

  getBase64(file:any, cb:any) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  doUpload = () => {
    const inputFile = document.getElementById('inputFile');
    if (inputFile) {
      inputFile.click();
    }
  } 

  onFileChange = (photo: any) => {
    var that = this;
    const token = (this.state as any).accessToken
    this.getBase64(photo, (base64:any)=> {
      if (!base64) return;
      const profile: any = Object.assign({}, (that.state as any).konProfile);
      profile.profile_pic = base64;
      that.setState({ konProfile: profile });
      let params = [{
        "userId": (that.state as any).konUserId,
        "entityId": 0,
        "photoBase64Data": base64,
        "photoFileName": photo.name,
        "fileSize": photo.size
      }];
      CommonService.postApiExecutor("savephoto", params, token
      ).then(response => {
        if (response.data.errorCode === 1) {
          notification.error({
            message: 'Error',
            description: response.data.description,
            placement: 'topRight'
          })
        } else {
          const document = [{
            "document_id": response.data.customData[0].documentId,
            "is_primary": 0
          }];
          this.setState({document: document});
          notification.success({
            message: 'Succes',
            description: 'Profile Updated Successfully',
            placement: 'topRight',
            duration: 1.5
          })
        }
      })
    })
  };

  

  removePhoto = () => {
    const profile: any = Object.assign({}, (this.state as any).konProfile);
    profile.profile_pic = null;
    this.setState({document: null});
    const inputFile = document.getElementById('inputFile');
    if (inputFile) {
      (inputFile as HTMLInputElement).value = '';
    }
  }

  // Auth.userAttributes returns an array of attributes.
  // We map it to an object for easy use.
  translateAttributes(data: any, key:any) {
    let retval: any = "";
    data
      .filter((attr: any) => [key].includes(attr.Name||attr.name))
      .forEach((attr: any) => retval = (attr.Value||attr.value));
    return retval;
  }

  async adminUpdateAttribute(key:any, isVerify?:any) {
    const profile: any = Object.assign({}, (this.state as any).profile);
    let phnNum = profile.phone_number;
    if (isVerify && localStorage.getItem("phone_to_verify")) {
      phnNum = localStorage.getItem("phone_to_verify");
    } else if (localStorage.getItem("verified_phone")) {
      phnNum = localStorage.getItem("verified_phone");
    }
    const uAttr:any = [{
      Name: key == 'phone'?'phone_number':key,
      Value: key == 'email' ? localStorage.getItem(isVerify ? "email_to_verify" : "verified_email") : 
      phnNum
    },
    {
      Name: key == 'email' ? 'email_verified' : 'phone_number_verified',
      Value: "true"
    }
  ];
    var that = this;
    const user: any = await Auth.currentAuthenticatedUser();
    var params1:any = {
      UserAttributes:uAttr,
      UserPoolId: user.pool.userPoolId, 
      Username: user.username
    };
    params1.type='AUUA';
    CommonService.portalApiCall("manageuser", params1, localStorage.getItem(AUTH_USER_TOKEN_KEY)
      ).then(response => {
        if (isVerify) {
          that.setState({verifyLoading: false});
          that.setState({openVerifyPopup: false});
          if (key == "email") {
            localStorage.removeItem("email_to_verify");
          } else {
            localStorage.removeItem("phone_to_verify");
          }
        }
        that.loadProfile();
      },
        err => {

        });
  }

  closeVerifyPop = () => {
    this.setState({verifyLoading: false});
    this.setState({openVerifyPopup: false});
  }

  verifyMethod = (key: string) => {
    if (key === 'phone_number' || key === 'phone') {
      this.verifyCognitoAttribute('phone_number');
      if (localStorage.getItem("verified_phone")) {
        this.adminUpdateAttribute('phone');
      }
    } else {
      this.verifyCognitoAttribute('email');
      this.adminUpdateAttribute('email');
    }
  }

  verifyCognitoAttribute = (key: string) => {
    key === 'phone_number' ? this.setState({verifyLoading: true}) : this.setState({verifyEmailLoading: true});
    this.setState({currentVerify: key});
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then(user => {
        Auth.verifyUserAttribute(user, key).then((res) => {
          this.setState({verifyLoading: false})
          notification.success({
            message: 'Succes',
            description: 'Verification code sent',
            placement: 'topRight',
            duration: 1.5
          });
          this.setState({openVerifyPopup: true});
        }).catch((error) => {
          this.setState({verifyLoading: false, verifyEmailLoading: true})
          notification.error({
            message: 'Error',
            description: 'Verification Failed',
            placement: 'topRight',
            duration: 1.5
          });
          console.error(error) // hitting error here 
        })
      })
  }

  confirmCognitoAttribute = (key:string, code:any) => {
    this.setState({verifyLoading: true});
    //this.adminUpdateAttribute(key, code);
    Auth.verifyCurrentUserAttributeSubmit(key, code).then((res) => {
      this.setState({verifyLoading: false})
      this.setState({openVerifyPopup: false});
      if (key == "email") {
        const profile: any = Object.assign({}, (this.state as any).profile);
        profile["email"] = localStorage.getItem("email_to_verify");
        this.setState({ profile: profile });
        localStorage.removeItem("email_to_verify");
        setTimeout(() => {
          this.saveProfile();
        }, 50);
      } else {
        localStorage.removeItem("phone_to_verify");
       this.loadProfile(true);
      }
      notification.success({
        message: 'Succes',
        description: `${key == 'email'? 'Email' : 'Phone number'} Successfully Verified`,
        placement: 'topRight',
        duration: 1.5
      });
    }).catch((error) => {
      this.setState({verifyLoading: false})
      notification.error({
        message: 'Error',
        description: error.message || error,
        placement: 'topRight'
      })
    })
  }

  onChangeType = (data:any) =>{
    this.setState({selectedType: data});
  }

  handleVerificationCode(value:any){
    this.setState({otp: value})
  }

  toggleTextView(type:any) {
    if (type === 'email') {
      this.setState({isEmailEdit: true});
    } else {
      this.setState({isPhoneEdit: true});
    }
  }

  mfaChange(val:any, flag?:any) {
    if (flag) {
      let UserMFASettingList: any = Object.assign([], (this.state as any).UserMFASettingList);
      if (val === '') {
        UserMFASettingList = UserMFASettingList.filter((x:any) => x !== flag);
        if ((this.state as any).PreferredMfaSetting === flag) {
          this.setState({PreferredMfaSetting: ''});
        }
      } else {
        if (UserMFASettingList.indexOf(val) === -1) {UserMFASettingList.push(val)};
      }
      this.setState({UserMFASettingList: UserMFASettingList});
      setTimeout(() => {
        this.setMFA(val);
      }, 100);
    } else {
      this.setState({PreferredMfaSetting: val});
      setTimeout(() => {
        this.setMFA(val, true);
      }, 100);
    }
  }

  setMFA(val:any, setPrefered?:boolean) {
    let smsPref = (this.state as any).PreferredMfaSetting === 'SMS_MFA' ? true : false;
    if (val === 'SMS_MFA' && setPrefered) {
      smsPref = true;
    }
    let softPref = (this.state as any).PreferredMfaSetting === 'SOFTWARE_TOKEN_MFA' ? true : false;
    if (val === 'SOFTWARE_TOKEN_MFA' && setPrefered) {
      softPref = true;
    }
    const param:any = {
      "Username": (this.state as any).cogUser.username,
      "UserPoolId": poolCongig.userPoolId,
      "SMSMfaSettings": {
        "Enabled": (this.state as any).UserMFASettingList.indexOf('SMS_MFA') > -1 ? true : false,
        "PreferredMfa": smsPref
      },
      "SoftwareTokenMfaSettings": { 
        "Enabled": (this.state as any).UserMFASettingList.indexOf('SOFTWARE_TOKEN_MFA') > -1 ? true : false,
        "PreferredMfa":  softPref
      }
    }

    /* if ((this.state as any).UserMFASettingList.indexOf('SMS_MFA') === -1 && (this.state as any).UserMFASettingList.indexOf('SOFTWARE_TOKEN_MFA') > -1) {
      delete param.SMSMfaSettings;
    } */
    if (val === 'SMS_MFA' && (this.state as any).UserMFASettingList.indexOf('SOFTWARE_TOKEN_MFA') === -1) {
      //param.SoftwareTokenMfaSettings = null;
      delete param.SoftwareTokenMfaSettings;
    } else if (val === 'SOFTWARE_TOKEN_MFA' && (this.state as any).UserMFASettingList.indexOf('SMS_MFA') === -1) {
      //param.SoftwareTokenMfaSettings = null;
      delete param.SMSMfaSettings;
    }

    if ((this.state as any).UserMFASettingList.indexOf('SMS_MFA') === -1 && (this.state as any).UserMFASettingList.indexOf('SOFTWARE_TOKEN_MFA') === -1) {
      param.SoftwareTokenMfaSettings.Enabled = false;
      param.SoftwareTokenMfaSettings.PreferredMfa = false;
      if (!((this.state as any).profile.phone_number && (this.state as any).profile.phone_number_verified)) {
        delete param.SMSMfaSettings;
      } else {
        param.SMSMfaSettings.Enabled = false;
        param.SMSMfaSettings.PreferredMfa = false;
      }
    }
    param.type = 'ASUMP';
    CommonService.portalApiCall("manageuser", param, localStorage.getItem(AUTH_USER_TOKEN_KEY)
    ).then(response => {
      console.log(response);
    },
    err => {

    })
  }

  addDevice(reset?:any) {
    if ((this.state as any).profile["custom:qrcodeurl"] && (this.state as any).profile["custom:qrcodeurl"] != "" && !reset) {
      this.setState({mfaUrl: (this.state as any).profile["custom:qrcodeurl"]});
      this.setState({shouldVerify: false});
    } else {
      var that = this;
      const  token:any = {"AccessToken": localStorage.getItem(AUTH_USER_TOKEN_KEY)};
      token.type = 'ASWT';
      if (token) {

        CommonService.portalApiCall("manageuser", token, localStorage.getItem(AUTH_USER_TOKEN_KEY)
        ).then(response => {
          if(response.data.errorCode === 0) {
            const makeUrl = `otpauth://totp/Konnexsion:${(that.state as any).profile.email}?secret=${response.data.customData.secretCode}&issuer=Konnexsion`
            const profile: any = Object.assign({}, (that.state as any).profile);
            profile["custom:qrcodeurl"] = makeUrl;
            that.setState({shouldVerify: true});
            if (!reset) {
              that.setState({mfaUrl: makeUrl, profile: profile})
            }
            if (reset) {
              profile["custom:qrcodeurl"] = "";
              if (profile["custom:mfasettings"]) {
                profile["custom:mfasettings"] = JSON.stringify({
                  "devices": []
                });
              }
              const mfaList =  Object.assign([], (that.state as any).UserMFASettingList);
              if (mfaList.indexOf("SOFTWARE_TOKEN_MFA") > -1) {
                if ((that.state as any).PreferredMfaSetting === 'SOFTWARE_TOKEN_MFA') {
                  that.setState({PreferredMfaSetting: '', UserMFASettingList: mfaList.filter((x:any) => x !== 'SOFTWARE_TOKEN_MFA'), profile: profile});
                } else {
                  that.setState({ UserMFASettingList: mfaList.filter((x:any) => x !== 'SOFTWARE_TOKEN_MFA'), profile: profile});
                }
                that.setMFA("NONE");
              } else {
                that.setState({profile: profile});
              }
              setTimeout(() => {
                that.saveProfile();
              }, 40);
              
            }
          } else {
            (this.props as any).history.push("/login");
          }
        },
          err => {

          });
      }
    }
  }

  verifyDevice(flag?:any) {
    const  token:any = {
      "AccessToken": localStorage.getItem(AUTH_USER_TOKEN_KEY),
      "FriendlyDeviceName": (this.state as any).deviceName,
      "UserCode": (this.state as any).mfaCode 
    };
    token.type='VSWT';
    if (!(this.state as any).shouldVerify) {
      const profile: any = Object.assign({}, (this.state as any).profile);
      let tempList:any = {
        "devices": []
      };
      if (profile["custom:mfasettings"]) {
        tempList = JSON.parse((this.state as any).profile["custom:mfasettings"]);
      }
      tempList.devices.push(token.FriendlyDeviceName);
      profile["custom:mfasettings"] = JSON.stringify(tempList);
      this.setState({ profile: profile });
      this.saveProfile();
    } else {
      var that = this;

      /* new CognitoIdentityServiceProvider().verifySoftwareToken(token, function (err, data) {
        if (err) console.log(err, err.stack);
        else {
          console.log(data);
          if (data.Status === 'SUCCESS') {
            const profile: any = Object.assign({}, (that.state as any).profile);
            if (!flag) {
              profile["custom:mfasettings"] = JSON.stringify({
                devices: [token.FriendlyDeviceName]
              });
              that.setState({ profile: profile });
            }
            that.saveProfile();
          }
        }
      }); */
      CommonService.portalApiCall("manageuser", token, localStorage.getItem(AUTH_USER_TOKEN_KEY)
      ).then(response => {
        if (response.data.errorCode === 0) {
          if (response.data.customData.status === 'SUCCESS') {
            const profile: any = Object.assign({}, (that.state as any).profile);
            if (!flag) {
              profile["custom:mfasettings"] = JSON.stringify({
                devices: [token.FriendlyDeviceName]
              });
              that.setState({ profile: profile });
            }
            that.saveProfile();
          }
        } else {
          (this.props as any).history.push("/login");
        }
      },
        err => {

        });
    }
  }

  render() {
    const { profile, konProfile, placeObj, toggleState, authEvents, deviceList, otp, openVerifyPopup, tabName } = this.state as any;
    return (
      <div className="contain-main-wrapper" >


        {/* <div className="page-heading">
          <h1 className="page-title">User Profile</h1>
          <p>Update your personal profile and login credentials </p>
        </div> */}
        <SideBar onToggle={this.doToggleState} userId={(this.state as any).konUserId}/>
        <div className="page-content fade-in-up">
          
          <div className="ibox boxshadowNone">
            <div className="ibox-head">
              <div className="ibox-title">
                {tabName}  
              </div>
              <div className="ibox-tools">
                {toggleState === 'profile' && (
                  <button type="button" className="btn btn-primary px-4 float-right" onClick={() => this.saveKonProfile()}>
                    {(this.state as any).loading && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                    Save Changes
                  </button>
                )}
                 {toggleState === 'profile' && (
                  <button type="button" className="btn btn-no-color px-4 float-right">Discard Changes</button>
                 )}
              </div>
            </div>
            <div className="ibox-body">
              {toggleState === 'profile' && (
                <Profile
                  konProfile={konProfile}
                  onFileChange={this.onFileChange}
                  doUpload={this.doUpload}
                  removePhoto={this.removePhoto}
                  phoneCounter={(this.state as any).phoneCounter}
                  phoneArray={(this.state as any).phoneArray}
                  phoneCahnge={this.phoneCahnge}
                  addnewPhone={this.addnewPhone}
                  removePhone={this.removePhone}
                  placeChanged={this.placeChanged}
                  placeObj={placeObj}
                  handleKonInputChange={this.handleKonInputChange}
                  loadMap={(this.state as any).loadMap}
                  contactTypeDropDown={(this.state as any).contactTypeDropDown}
                  onChangeType={this.onChangeType}
                  selectedType={(this.state as any).selectedType}
                  defaultCountry={(this.state as any).countryCode}
                />
              )}
              {toggleState === 'login' && (
                <LoginDetail
                  handleVerificationCode={this.handleVerificationCode}
                  confirmCognitoAttribute={this.confirmCognitoAttribute}
                  verifyCognitoAttribute={this.verifyCognitoAttribute}
                  handleInputChange={this.handleInputChange}
                  profile={profile}
                  openVerifyPopup={openVerifyPopup}
                  otp={otp}
                  loading={(this.state as any).loading}
                  verifyLoading={(this.state as any).verifyLoading}
                  verifyEmailLoading={(this.state as any).verifyEmailLoading}
                  socialCallBack={this.socialCallBack}
                  socialUsers={(this.state as any).socialUsers}
                  removeSocial={this.removeSocial}
                  currentVerify={(this.state as any).currentVerify}
                  toggleTextView={this.toggleTextView}
                  isEmailEdit={(this.state as any).isEmailEdit}
                  isPhoneEdit={(this.state as any).isPhoneEdit}
                  saveFunc={this.saveProfile}
                  addDevice={this.addDevice}
                  mfaUrl={(this.state as any).mfaUrl}
                  handleMfaChange={this.handleMfaChange}
                  mfaCode={(this.state as any).mfaCode}
                  verifyDevice={this.verifyDevice}
                  deviceName={(this.state as any).deviceName}
                  PreferredMfaSetting={(this.state as any).PreferredMfaSetting}
                  UserMFASettingList={(this.state as any).UserMFASettingList}
                  mfaChange={this.mfaChange}
                  closeVerifyPop={this.closeVerifyPop}
                  showCode={(this.state as any).showCode}
                />
              )}
              {toggleState === 'authlog' && (
                <AuthLog authEvents={authEvents}/>
              )}
              {toggleState === 'device' && (
                <DeviceLog deviceList={deviceList} translateAttributes={this.translateAttributes}/>
              )}
              {toggleState == 'subscription' && (
                <Subscription profileDetails={(this.state as any).konProfile}/>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ProfileContainer;