import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/App.css';
import './assets/css/style.css'; 
import './assets/css/bootstrap-social-media.css'; 
import './assets/css/template.css'; 
import './assets/css/font-awesome.css';
import './assets/css/line-awesome.css';
import './assets/css/responsive.css'; 

/** Presentational */
import App from './Components/App';
// import { GlobalStyles } from './global';

/** Service worker */
import * as serviceWorker from './serviceWorker';
import { poolCongig } from './constants';

/** Configure amplify */
Amplify.configure(poolCongig);

ReactDOM.render(
  <>
    {/* <GlobalStyles /> */}
    <App />
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
