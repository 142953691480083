import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import Amplify, { Auth } from 'aws-amplify';
import { useForm } from "react-hook-form";
import isEmail from "validator/lib/isEmail";
import { notification } from 'antd';
// import AWS from 'aws-sdk';
// import CognitoIdentityServiceProvider from 'aws-sdk/clients/cognitoidentityserviceprovider';
// import GoogleLogin from 'react-google-login';
import queryString from 'query-string';
/** Helpers */
import { validateToken, isRevoked } from '../../Utils/Helpers';
import CommonService from '../../Services/CommonService';
import { poolCongig, gClientId } from '../../constants';
import pageConf from '../../pageConfig.json';
import Modal from 'react-bootstrap/Modal';
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
/** App constants */
import { AUTH_USER_DEVICE_KEY, AUTH_USER_TOKEN_KEY } from '../../Utils/constants';

const jwtDecode = require('jwt-decode');

const reCapKey = process.env.REACT_APP_RECAPTCHA_KEY || '6Lfj6v8ZAAAAAGoIFhzh5fm63FUrJLwsko4XWiDU';
type User = {
  username: string;
  password: string;
  devicename: string;
  mfa: string;
  nps: string;
};

/** 
 * @component 
 * */
const LoginContainer = (props: any) => {
  const history = useHistory();
  const checkUserAuth = validateToken(localStorage.getItem(AUTH_USER_TOKEN_KEY));
  const coreQuery = props.location.search;
  const currentYear = new Date().getFullYear();
  const licenceAgreementUrl = process.env.REACT_APP_LICENCE_URL || "https://legal.kpm.one/eula";
  const query = queryString.parse(props.location.search);
  const { register, handleSubmit, errors, getValues } = useForm<User>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingMgl, setIsSubmittingMgl] = useState(false);
  const [requestMgl, setRequestMgl] = useState(false);
  const [socialLoading, setSocialLoading] = useState(false);
  const [currentState, setCurrentState] = useState(""); //useState("MGLS");
  const [authUser, setAuthUser] = useState(null as any);
  const [resendEmail, setResendEmail] = useState("");
  const [mfaType, setMfaType] = useState("");
  const [mfaParam, setMfaParam] = useState({} as any);
  const [choosenMFA, setChoosenMFA] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [credStore, setCredStore] = useState([] as any);
  const [magiclinkerr, setMagiclinkerr] = useState("");
  const [mglemail, setMglemail] = useState("");
  const [deviceKeyExist, setdeviceKeyExist] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const recaptchaRef = React.useRef();
  
  let toggleClassCheck = showPassword ? 'fa-eye' : 'fa-eye-slash';
  let passwordType = showPassword ? 'text' : 'password';
  /**
   * When KPM or Portal app redirect to Auth app for Authentication , this method checks if there is already active
   * session and redirect back to the original app with auth token. If session expired, login screen made visible and
   * after successfun authentication it redirect back to the original app with auth token.
   */
  useEffect(()=>{
    if (query && query.id && query.type === 'magiclink') {
      processMagiclink(query.id);
      setCurrentState("VERIFY_MAGIC_LINK");
      
    } else if (query && query.type === 'requestmagiclink') {
      setCurrentState("REQUEST_MAGIC_LINK");

      let requestEmail = query.email?query.email:"";
      requestEmail = requestEmail.toString();
      
      if(requestEmail && isEmail(requestEmail)){
        setRequestMgl(true);
        setMglemail(requestEmail);
        let params = {
          emailId:requestEmail,
          referrer:document.referrer,
          payload:JSON.stringify({
            ...query
          })
        }
        CommonService.portalApiCall("rest/user/magiclink", params
        ).then((response:any) => {
          setRequestMgl(false);
          setCurrentState("MGLS"); // Magic Link Success
        },
        (err:any) => {
          setRequestMgl(false);
        })
      } else {
        notification.error({
          message: 'Error',
          description: "Invalid email address.",
          placement: 'topRight'
        });
      }
    } else {
      setCurrentState("LOGIN");
    }
    let deviceKey = localStorage.getItem(AUTH_USER_DEVICE_KEY);
    if(deviceKey){
      setdeviceKeyExist(true);
    }
  },[]);

  const processMagiclink = (guid:any) => {
    let params = {
      magicLinkGuid:guid
    }
    CommonService.portalApiCall("rest/user/verifymagiclink", params
    ).then((response:any) => {
      if(response.data.errorCode){
        if(response.data.description){
          notification.error({
            message: 'Error',
            description: response.data.description,
            placement: 'topRight'
          });
        }
        //localStorage.clear(); --Preserve Device Tracking
        localStorage.removeItem(AUTH_USER_TOKEN_KEY);
        localStorage.removeItem('verified_email');
        localStorage.removeItem('verified_phone');
        window.open(window.location.origin,"_self");

      } else {
        const data = response.data.customData;
        localStorage.setItem(AUTH_USER_TOKEN_KEY, data.accessToken);
        let userObject = jwtDecode(data.accessToken);
        Auth.signIn(userObject.sub)
        .then(user => {
          setSocialLoading(false);
          notification.success({
            message: 'Succesfully logged in!',
            description: 'Logged in successfully, Redirecting you in a few!',
            placement: 'topRight',
            duration: 1.5
          });

          if(data.userId){
            let responsePayload = data.payload?data.payload:{};
            let deviceKey = localStorage.getItem(AUTH_USER_DEVICE_KEY);
            let ssoParams = {
              userId:data.userId, 
              calltype:"logon", 
              payload: JSON.stringify(responsePayload),
              deviceKey:deviceKey,
              ...responsePayload
            }
            CommonService.postApiExecutor("requestsso",ssoParams, 
            data.accessToken,false,null,true).then(response => {
                if (response.data.errorCode !== 0) {
                  if(response.data.description){
                    notification.error({
                      message: 'Error',
                      description: response.data.description,
                      placement: 'topRight'
                    });
                  }
                } else {
                  let ssoToken = response.data.customData?response.data.customData.id:"";
                  let destination = response.data.customData?response.data.customData.destinationDomain:"";
  
                  if(ssoToken && destination){
                      window.open(destination + "/auth?sso_token=" + ssoToken, '_self');
                  } else if(response.data.customData.url){
                    window.open(response.data.customData.url, '_self');
                  } else {
                    history.push("/profile");
                  }
                }
            }).catch(err => {
              notification.error({
                message: 'Error',
                description: err.message,
                placement: 'topRight'
              });
            });
          } else {
            history.push("/profile");
          }
          
        }).catch(err => {
          notification.error({
            message: 'Error',
            description: err.message,
            placement: 'topRight'
          });
        });
      
      }
    },
    (err:any) => {
      notification.error({
        message: 'Error',
        description: err.message,
        placement: 'topRight'
      });
    })
  }
  const processLogout = async (guid:any) => {
    try {
      await Auth.signOut({ global: true }).then(() => {
        localStorage.removeItem(AUTH_USER_TOKEN_KEY);
        processMagiclink(guid);
      });
    } catch (err:any) {
      notification.error({ message: err.message });
    }
  };

  const triggerSsoRedirection = async (isLogin?:any) =>{

    if(query && query.site != 'id'){
      const user: any = await Auth.currentAuthenticatedUser();
      let ssoParam = {
        userId:user.attributes["custom:kpmUserId"],
        payload:JSON.stringify({
          user_id:user.attributes["custom:kpmUserId"],
          entity_id:null,
          email_id:localStorage.getItem("verified_email")?localStorage.getItem("verified_email"):""
        }),
        calltype:"logon",
        referrer:document.referrer
      };
  
      let deviceKey = localStorage.getItem(AUTH_USER_DEVICE_KEY);
      let mergedParams = {
        deviceKey:deviceKey,
        ...ssoParam,
        ...query
      };
      CommonService.postApiExecutor("requestsso", 
      mergedParams, 
      localStorage.getItem(AUTH_USER_TOKEN_KEY),false,null,true).then(response => {
        if (response.data.errorCode !== 0) {
  
        } else {
          let ssoToken = response.data.customData?response.data.customData.id:"";
          let destination = response.data.customData?response.data.customData.destinationDomain:"";
  
          if(ssoToken && destination){
            window.open(destination + "/auth?sso_token=" + ssoToken, '_self');
          } else if(response.data.customData.url){
            window.open(response.data.customData.url, '_self');
          } else {
            if(isLogin){
              setCurrentState("LOGIN");
            } else {
              history.push("/profile");
            }
          }
        }
      })
    } else {
      if(isLogin){
        setCurrentState("LOGIN");
      } else {
        history.push("/profile");
      }
    }
  }

  const sendAppCommunication = (sentData:any) =>{
    var msgJson = JSON.stringify(sentData);
  
    if(!(window as any).webkit.messageHandlers.cordova_iab){
      console.warn('Cordova IAB postMessage API not found!');
      throw 'Cordova IAB postMessage API not found!';
    }else{
      console.log('Message sent!');
      (window as any).webkit.messageHandlers.cordova_iab.postMessage(msgJson);
    }
    return false;
  }
  const checkRedirect = async () => {
    if (checkUserAuth) {
      const isRvkd = await isRevoked(localStorage.getItem(AUTH_USER_TOKEN_KEY));
      if ((query.site || query.host || query.returnurl || query.returnUrl)) {
        if (isRvkd) {
          //localStorage.clear(); Preserve Device Tracking
          localStorage.removeItem(AUTH_USER_TOKEN_KEY);
          localStorage.removeItem('verified_email');
          localStorage.removeItem('verified_phone');

          history.push({
            pathname: '/login',
            search: props.location.search
          }
          );
        } else {

          triggerSsoRedirection();
          /*
          if (query && query.site === 'portal') {
            const tempLst = localStorage.getItem('PORTAL_LIST');
            if (tempLst) {
              const lst = JSON.parse(tempLst as any);
              if (query.host === 'localhost' || lst.find((x:any)=> x.dns_endpoint === query.host)) {
                window.location.assign("https://" + query.host + "/auth?id_token=" + localStorage.getItem(AUTH_USER_TOKEN_KEY) + "&returnUrl=" + query.returnUrl);
              } else {
                window.location.assign("https://" + query.host + "/auth?mode=guest&token=" + localStorage.getItem(AUTH_USER_TOKEN_KEY) + "&returnUrl=" + encodeURIComponent(query.returnUrl as any));
              }
            } else {
              const user: any = await Auth.currentAuthenticatedUser();
              getPortalList(user.signInUserSession.accessToken.jwtToken, user.attributes["custom:kpmUserId"]);
            }
          } else if (query && query.site === 'kpm') {
            //window.location.assign("https://" + query.host +"/auth" + coreQuery + "&id_token=" + localStorage.getItem(AUTH_USER_TOKEN_KEY));
            window.location.assign(process.env.REACT_APP_KPM_URL as any+"/auth" + coreQuery + "&id_token=" + localStorage.getItem(AUTH_USER_TOKEN_KEY));
          } else if (query && query.site === 'forum') {
            const user: any = await Auth.currentAuthenticatedUser();
            generateForumUrl(user);
          } else if (query && query.site === 'app') {
            sendAppCommunication({ message: JSON.stringify({"id_token":localStorage.getItem(AUTH_USER_TOKEN_KEY)}) });
          } else {
            history.push("/profile");
          }*/

        }
      } else if (!isRvkd) {
        history.push({
          pathname: '/profile'
        })
      }
    }
  }
  checkRedirect();

  /**
   * This method returns list of portals on which current user have access.
   * If Current user try to access a portal that is not present in the list, only guest mode access is given. 
   * @param {string} token Access token of current authenticated user 
   * @param {number} userId KPM user id of current authenticated user
   */
  const getPortalList = (token:any, userId:any)=> {
    CommonService.portalApiCall("rest/getuserportalsites", {userId: userId}
      ).then((response:any) => {

        const data = response.data.customData[0].array_to_json;
        if (data != null) {
          const lst = JSON.parse(data.value);
          localStorage.setItem('PORTAL_LIST', data.value);
          if (query.host=='localhost' || lst.find((x:any)=> x.dns_endpoint == query.host)) {
            window.location.assign("https://" + query.host + "/auth?id_token=" + token + "&returnUrl=" + query.returnUrl);
          } else {
            window.location.assign("https://" + query.host + "/auth?mode=guest&token=" + token + "&returnUrl=" + encodeURIComponent(query.returnUrl as any));
          }
        } else {
          window.location.assign("https://" + query.host + "/auth?mode=guest&token=" + token + "&returnUrl=" + encodeURIComponent(query.returnUrl as any));
        }
      },
      (err:any) => {
        window.location.assign("https://" + query.host + "/auth?mode=guest&token=" + token + "&returnUrl=" + encodeURIComponent(query.returnUrl as any));
      })
  }

  /**
   * Sends activation link through email for a signed up user with inactive account.
   */
  const sendAgain = () => {
    setIsSubmitting(true);
    Auth.resendSignUp(resendEmail)
      .then(() => {
        setIsSubmitting(false);
        notification.success({
          message: 'Succes',
          description: 'An activation Link sent to you',
          placement: 'topRight',
          duration: 1.5
        });
      })
      .catch((err) => {
        setIsSubmitting(false);
        notification.error({
          message: 'Error',
          description: err.message,
          placement: 'topRight'
        });
      });
  }

  const performSignIn = (username: any, password: any) => {
    Auth.signIn(username, password)
      .then(user => {
        setIsSubmitting(false);
        if (user.challengeName === 'SMS_MFA' ||
          user.challengeName === 'SOFTWARE_TOKEN_MFA') {
          setAuthUser(user);
          setMfaType(user.challengeName);
          setCurrentState("MFA");
          setResendEmail(username);

        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setAuthUser(user);
          setCurrentState("NPR");
          setResendEmail(username);
        } else {
          localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.accessToken.jwtToken);
          notification.success({
            message: 'Succesfully logged in!',
            description: 'Logged in successfully, Redirecting you in a few!',
            placement: 'topRight',
            duration: 1.5
          });
          setIsSubmitting(false);
          triggerSsoRedirection();
          /*
          if (query && query.site === 'portal') {
            getPortalList(user.signInUserSession.accessToken.jwtToken, user.attributes["custom:kpmUserId"]);
            //window.location.assign("https://" + query.host + "/auth?id_token=" + user.signInUserSession.accessToken.jwtToken + "&returnUrl=" + query.returnUrl);
          } else if (query && query.site === 'kpm') {
            //window.location.assign("https://" + query.host +"/auth" + coreQuery + "&id_token=" + user.signInUserSession.accessToken.jwtToken);
            window.location.assign(process.env.REACT_APP_KPM_URL as any+"/auth" + coreQuery + "&id_token=" + user.signInUserSession.accessToken.jwtToken);
          } else if (query && query.site === 'forum') {
            generateForumUrl(user, true);
          } else if (query && query.site === 'app') {
            console.log("Posting",query.site);
            sendAppCommunication({ message: JSON.stringify({"id_token":user.signInUserSession.accessToken.jwtToken}) });
          } else {
            history.push("/profile");
          }*/
        }
      })
      .catch(err => {
        setIsSubmitting(false);
        notification.error({
          message: 'Error',
          description: err.message,
          placement: 'topRight'
        });
        if (err.code === "UserNotConfirmedException") {
          setCurrentState("NF");
          setResendEmail(username);
        }
      });
  }

  /**
   * This method invoked on login form submit and following steps are done-
   * <ul>
   *  <li>An api call made with emailId to check if the user present in Amazon Cognito.</li>
   *  <li>If the user is a kpm user and not present in cognito, the api returns Y and authenticationFlowType set to
   * USER_PASSWORD_AUTH. Amplify Auth.signin method called  and user migration lambda fired.After migration and signin completion
   * Exsiting kpm user updated with newly created cognito sub id. After that the session stored in localstorage and additional steps like 
   * MFA, Force Password reset are performed based on the response received from cognito signin.</li>
   *  <li>If the user is present in both cognito and kpm databas then normal signin process happened with authenticationFlowType USER_SRP_AUTH</li>
   * </ul>
   * @param {object} event Login form event object
   */

  const storeDevice = (deviceName:string,refToken:string)=>{

    let deviceKey = localStorage.getItem(AUTH_USER_DEVICE_KEY);
    let accessToken = localStorage.getItem(AUTH_USER_TOKEN_KEY);
    let ssoParams = {
      deviceKey:deviceKey?deviceKey:null, 
      deviceName:deviceName, 
      refreshToken: refToken
    }
    CommonService.postApiExecutor("requestsso",ssoParams, 
    accessToken,false,null,true).then(response => {
        if (response.data.errorCode !== 0) {
          if(response.data.description){
            notification.error({
              message: 'Error',
              description: response.data.description,
              placement: 'topRight'
            });
          }
        } else {
          
        }
    }).catch(err => {
      notification.error({
        message: 'Error',
        description: err.message,
        placement: 'topRight'
      });
    });

  }
  const onSubmit = (event: User) => {
    // event.preventDefault();
    //localStorage.clear(); Preserve Device Tracking
    sessionStorage.clear();
    let { username, password, devicename } = event;
    setCredStore([username, password]);
    setIsSubmitting(true);
    CommonService.postApiExecutor("rest/checkkpmuser",
      { emailId: username }).then((response:any) => {
        
        if (response.data.customData === 'Y') {
          poolCongig.authenticationFlowType = 'USER_PASSWORD_AUTH';
          Amplify.configure(poolCongig);
        } else {
          poolCongig.authenticationFlowType = 'USER_SRP_AUTH';
          Amplify.configure(poolCongig);
        }
        Auth.signIn(username, password)
          .then(user => {

            if (response.data.customData === 'Y') {
              poolCongig.authenticationFlowType = 'USER_SRP_AUTH';
              Amplify.configure(poolCongig);
              CommonService.postApiExecutor("rest/updatecognitouser",
                { emailId: username, subId: user.signInUserSession.accessToken.payload.sub }).then((response:any) => {
                  performSignIn(username, password);
                })
            } else {
              setIsSubmitting(false);
              if (user.challengeName === 'SMS_MFA' ||
                user.challengeName === 'SOFTWARE_TOKEN_MFA' || user.challengeName === 'SELECT_MFA_TYPE') {
                setAuthUser(user);
                setMfaType(user.challengeName);
                setCurrentState(user.challengeName);
                setResendEmail(username);
                setMfaParam(user.challengeParam);
                if (user.challengeName === 'SELECT_MFA_TYPE') {
                  setOpenModal(true);
                  user.sendMFASelectionAnswer()
                }

              } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setAuthUser(user);
                setCurrentState("NPR");
                setResendEmail(username);
              } else {
                localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.accessToken.jwtToken);
                // Extracting device key
                if(user){
                  let storagePrefix = user.keyPrefix;
                  let username = user.username;
                  let deviceKey = user.storage[storagePrefix+"."+username+".deviceKey"];
                  let refToken = user.storage[storagePrefix+"."+username+".refreshToken"];
                  if(deviceKey){
                    localStorage.setItem(AUTH_USER_DEVICE_KEY,deviceKey);
                  }
                  //if(devicename){
                  storeDevice(devicename,refToken);
                  //}
                }
                notification.success({
                  message: 'Succesfully logged in!',
                  description: 'Logged in successfully, Redirecting you in a few!',
                  placement: 'topRight',
                  duration: 1.5
                });
                setIsSubmitting(false);
                triggerSsoRedirection();
                /*
                if (query && query.site === 'portal') {
                  getPortalList(user.signInUserSession.accessToken.jwtToken, user.attributes["custom:kpmUserId"]);
                 //window.location.assign("https://" + query.host + "/auth?id_token=" + user.signInUserSession.accessToken.jwtToken + "&returnUrl=" + query.returnUrl);
                } else if (query && query.site === 'kpm') {
                  //window.location.assign("https://" + query.host +"/auth" + coreQuery + "&id_token=" + user.signInUserSession.accessToken.jwtToken);
                  window.location.assign(process.env.REACT_APP_KPM_URL as any+"/auth" + coreQuery + "&id_token=" + user.signInUserSession.accessToken.jwtToken);
                } else if (query && query.site === 'forum') {
                  generateForumUrl(user, true);
                }  else if (query && query.site === 'app') {
                  console.log("Posting",query.site);
                  sendAppCommunication({ message: JSON.stringify({"id_token":user.signInUserSession.accessToken.jwtToken}) });
                } else {
                  history.push("/profile");
                }*/
              }
            }
          })
          .catch(err => {
            setIsSubmitting(false);
            notification.error({
              message: 'Error',
              description: err.name === 'UserNotFoundException'? 'Incorrect username or password.' : err.message,
              placement: 'topRight'
            });
            if (err.code === "UserNotConfirmedException") {
              setCurrentState("NF");
              setResendEmail(username);
            }
          });
      }).catch((err:any) => {
        notification.error({
          message: 'Error',
          description: err.message,
          placement: 'topRight'
        });
      });
  };

  const sendMagicLink = () =>{
    
    let enteredEmail = getValues("username");
    if(enteredEmail && isEmail(enteredEmail)){
      setIsSubmittingMgl(true);
      setMglemail(enteredEmail);
      let params = {
        emailId:enteredEmail,
        referrer:document.referrer,
        payload:JSON.stringify({
          ...query
        })
      }
      CommonService.portalApiCall("rest/user/magiclink", params
      ).then((response:any) => {
        setIsSubmittingMgl(false);
        setCurrentState("MGLS"); // Magic Link Success
      },
      (err:any) => {
        setIsSubmittingMgl(false);
      })
    } else {
      setMagiclinkerr("Please enter a valid email address.");
    }
  }
  /**
   * This method invoked after login submit if MFA enabled for the user. User Prompted to submit a mfa code from
   * MFA device or SMS
   * @param {object} event MFA form submit event 
   */
  const onMFA = (event: any) => {
    setIsSubmitting(true);
    Auth.confirmSignIn(authUser, event.mfa, mfaType as any)
      .then(user => {
        localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.accessToken.jwtToken);
        notification.success({
          message: 'Succesfully logged in!',
          description: 'Logged in successfully, Redirecting you in a few!',
          placement: 'topRight',
          duration: 1.5
        });
        setIsSubmitting(false);
        triggerSsoRedirection();
        /*
        if (query && query.site === 'portal') {
          getPortalList(user.signInUserSession.accessToken.jwtToken, user.attributes["custom:kpmUserId"]);
          //window.location.assign("https://" + query.host + "/auth?id_token=" + user.signInUserSession.accessToken.jwtToken + "&returnUrl=" + query.returnUrl);
        } else if (query && query.site === 'kpm') {
          //window.location.assign("https://" + query.host +"/auth" + coreQuery + "&id_token=" + user.signInUserSession.accessToken.jwtToken);
          window.location.assign(process.env.REACT_APP_KPM_URL as any+"/auth" + coreQuery + "&id_token=" + user.signInUserSession.accessToken.jwtToken);
        } else if (query && query.site === 'forum') {
          generateForumUrl(user, true);
        } else if (query && query.site === 'app') {
          console.log("Posting",query.site);
          sendAppCommunication({ message: JSON.stringify({"id_token":user.signInUserSession.accessToken.jwtToken}) });
        } else {
          history.push("/profile")
        }*/
      }
      )
      .catch(err => {
        setIsSubmitting(false);
        notification.error({
          message: 'Error',
          description: err.message,
          placement: 'topRight'
        });
      });
  }

  /**
   * This method invoked if MFA enabled but no preferred mfa type(SMS/DEVICE) selected.
   * In this case User Prompted to select a type from radio group and through admin function preference is set.
   */
  const setMFAResponse = () => {
    const param: any = {
      "Username": authUser.username,
      "UserPoolId": poolCongig.userPoolId
    }
    if (choosenMFA === 'SOFTWARE_TOKEN_MFA') {
      param["SoftwareTokenMfaSettings"] = {
        "Enabled": true,
        "PreferredMfa": true
      }
    } else {
      param["SMSMfaSettings"] = {
        "Enabled": true,
        "PreferredMfa": true
      }
    }
    param.type = 'ASUMP';
    CommonService.portalApiCall("rest/selectpreference", param, localStorage.getItem(AUTH_USER_TOKEN_KEY)
    ).then((response:any) => {
      Auth.signIn(credStore[0], credStore[1]).then(user => {
        if (user.challengeName === 'SMS_MFA' ||
          user.challengeName === 'SOFTWARE_TOKEN_MFA' || user.challengeName === 'SELECT_MFA_TYPE') {
          setAuthUser(user);
          setMfaType(user.challengeName);
          setCurrentState(user.challengeName);
          setResendEmail(credStore[0]);
          setMfaParam(user.challengeParam);
          if (user.challengeName === 'SELECT_MFA_TYPE') {
            setOpenModal(true);
            user.sendMFASelectionAnswer()
          }

        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setAuthUser(user);
          setCurrentState("NPR");
          setResendEmail(credStore[0]);
        } else {
          localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.accessToken.jwtToken);
          notification.success({
            message: 'Succesfully logged in!',
            description: 'Logged in successfully, Redirecting you in a few!',
            placement: 'topRight',
            duration: 1.5
          });
          setIsSubmitting(false);
          triggerSsoRedirection();

          /*
          if (query && query.site === 'portal') {
            //window.location.assign("https://" + query.host + "/auth?id_token=" + user.signInUserSession.accessToken.jwtToken + "&returnUrl=" + query.returnUrl);
          } else if (query && query.site === 'kpm') {
            //window.location.assign("https://" + query.host +"/auth" + coreQuery + "&id_token=" + user.signInUserSession.accessToken.jwtToken);
            window.location.assign(process.env.REACT_APP_KPM_URL as any+"/auth" + coreQuery + "&id_token=" + user.signInUserSession.accessToken.jwtToken);
          } else if (query && query.site === 'forum') {
            generateForumUrl(user, true);
          } else if (query && query.site === 'app') {
            console.log("Posting",query.site);
            sendAppCommunication({ message: JSON.stringify({"id_token":user.signInUserSession.accessToken.jwtToken}) });
          } else {
            history.push("/profile");
          }*/

        }

      }).catch(err => {
        notification.error({
          message: 'Error',
          description: err.message,
          placement: 'topRight'
        });
        if (err.code === "UserNotConfirmedException") {
          setCurrentState("NF");
          setResendEmail(credStore[0]);
        }
      });
    })
  }

  /**
   * This method invoked when a newly created user tries to logon with temporary password for the first time.
   * It forced user to set a new password through completeNewPassword method
   */
  const onNewPass = async (event: any) => {
    setIsSubmitting(true);
    const token = await (recaptchaRef as any)!.current.executeAsync();
    Auth.completeNewPassword(authUser, event.nps, {})
      .then((user: any) => {
        localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.accessToken.jwtToken);
        CommonService.postApiExecutor("rest/updateuserattribute", 
        { emailId: user.signInUserSession.idToken.payload.email, rcToken:token }, user.signInUserSession.accessToken.jwtToken
        ).then(() => {
          notification.success({
            message: 'Succes',
            description: 'Password Changed',
            placement: 'topRight',
            duration: 1.5
          });
          setIsSubmitting(false);
          triggerSsoRedirection(true);

          /*
          if (query && query.site === 'portal') {
            if (query.host === 'app2.kpmdev.one') {
              window.location.assign("https://" + query.host + "/auth?mode=guest&token=" + user.signInUserSession.accessToken.jwtToken + "&returnUrl=" + encodeURIComponent(query.returnUrl as any));
            } else {
              window.location.assign("https://" + query.host + "/auth?id_token=" + user.signInUserSession.accessToken.jwtToken + "&returnUrl=" + query.returnUrl);
            }
          } else if (query && query.site === 'kpm') {
            window.location.assign(process.env.REACT_APP_KPM_URL as any+"/auth" + coreQuery + "&id_token=" + user.signInUserSession.accessToken.jwtToken);
          }  else if (query && query.site === 'app') {
            console.log("Posting",query.site);
            sendAppCommunication({ message: JSON.stringify({"id_token":user.signInUserSession.accessToken.jwtToken}) });
          } else {
            setCurrentState("LOGIN");
          }*/


        }).catch((err:any) => {
          notification.error({
            message: 'Error',
            description: err.message,
            placement: 'topRight'
          });
        });
        
      }
      )
      .catch((err: any) => {
        setIsSubmitting(false);
        notification.error({
          message: 'Error',
          description: err.message,
          placement: 'topRight'
        });
      });
  }

  const hasIdentities = (val: any) => {
    return JSON.parse(val).length > 0;
  }

  const matchIdentities = (val: any, match: any) => {
    return JSON.parse(val).find((x: any) => x.userId === match);
  }

  const customAuth = (userId: any) => {
    Auth.signIn(userId)
      .then(user => {
        setSocialLoading(false);
        if(user.signInUserSession){
          notification.success({
            message: 'Succesfully logged in!',
            description: 'Logged in successfully, Redirecting you in a few!',
            placement: 'topRight',
            duration: 1.5
          });
          
          localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.accessToken.jwtToken);
          triggerSsoRedirection();
          
        } else {
          notification.error({
            message: 'Error',
            description: "Login Failed. Please try again !",
            placement: 'topRight'
          });
          history.push({
            pathname: '/login',
            search: ''
          });
        }
        
        /*
        if (query && query.site === 'portal') {
          getPortalList(user.signInUserSession.accessToken.jwtToken, user.attributes["custom:kpmUserId"]);
          //window.location.assign("https://" + query.host + "/auth?id_token=" + user.signInUserSession.accessToken.jwtToken + "&returnUrl=" + query.returnUrl);
        } else if (query && query.site === 'kpm') {
          //window.location.assign("https://" + query.host +"/auth" + coreQuery + "&id_token=" + user.signInUserSession.accessToken.jwtToken);
          window.location.assign(process.env.REACT_APP_KPM_URL as any+"/auth" + coreQuery + "&id_token=" + user.signInUserSession.accessToken.jwtToken);
        } else if (query && query.site === 'forum') {
          generateForumUrl(user, true);
        } else if (query && query.site === 'app') {
          console.log("Posting",query.site);
          sendAppCommunication({ message: JSON.stringify({"id_token":user.signInUserSession.accessToken.jwtToken}) });
        } else {
          history.push("/profile");
        }*/

      });
  }

  const checkEmailLink = (poolData: any, match: any, pId: any, metaData: any) => {
    const filteredUsers = poolData.filter((x: any) => x.Attributes.filter((y: any) => y.Value === match).length > 0);
    if (filteredUsers.length > 0) {
      if (hasIdentities(filteredUsers[0].Attributes.filter((y: any) => y.Name === "identities")[0].Value)) {
        customAuth(filteredUsers[0].Username);
      } else {
        linkProviderToUser(filteredUsers[0].Username, poolCongig.userPoolId, "Google", pId, metaData);
      }
    }
  }

  const getUserByEmail = (userPoolId: any, email: any, emailOne: any, metaData: any) => {
    const params: any = {
      UserPoolId: userPoolId,
      //Filter: `email = "${email}"`
    }
    params.type = 'LU';

    CommonService.portalApiCall("manageuser", params, localStorage.getItem(AUTH_USER_TOKEN_KEY)
    ).then((response:any)=> {
      if (response.data.errorCode === 0) {
        const filteredUsers = response.data.customData.Users.filter((x: any) => x.Attributes.filter((y: any) => y.Name === "identities").length > 0);
        if (filteredUsers.length > 0) {
          const lvlFilter = filteredUsers.filter((x: any) => hasIdentities(x.Attributes.filter((y: any) => y.Name === "identities")[0].Value));
          if (lvlFilter.length > 0) {
            const finalMatch = lvlFilter.filter((x: any) => matchIdentities(x.Attributes.filter((y: any) => y.Name === "identities")[0].Value, email));
            if (finalMatch.length > 0) {
              customAuth(finalMatch[0].Username);
            } else {
              if (response.data.customData.Users.filter((x: any) => x.Attributes.filter((y: any) => y.Value === emailOne).length > 0).length > 0) {
                checkEmailLink(response.data.customData.Users, emailOne, email, metaData);
              } else {
                setSocialLoading(false);
                notification.error({
                  message: 'Error',
                  description: "User Not Found",
                  placement: 'topRight'
                });
              }
            }
          } else {
            if (response.data.customData.Users.filter((x: any) => x.Attributes.filter((y: any) => y.Value === emailOne).length > 0).length > 0) {
              checkEmailLink(response.data.customData.Users, emailOne, email, metaData);
            } else {
              setSocialLoading(false);
              notification.error({
                message: 'Error',
                description: "User Not Found",
                placement: 'topRight'
              });
            }
          }

        } else {
          if (response.data.customData.Users.filter((x: any) => x.Attributes.filter((y: any) => y.Value === emailOne).length > 0).length > 0) {
            checkEmailLink(response.data.customData.Users, emailOne, email, metaData);
          } else {
            setSocialLoading(false);
            notification.error({
              message: 'Error',
              description: "User Not Found",
              placement: 'topRight'
            });
          }
        }
      } else {
        history.push("/login");
      }
    });
  }

  /**
   * This method links google account to a cognito account.After linking is done
   * this record stored in a custom cognito attribute 'custom:socialIdentities'.
   * @param {string} username Cognito username
   * @param {string} userPoolId Cognito userPoolId
   * @param {string} providerName Third party provider(Google)
   * @param _providerUserId 
   * @param {object} response Google profile Object 
   */
  const linkProviderToUser = (username: any, userPoolId: any, providerName: any, _providerUserId: any, response: any) => {
    const params: any = {
      DestinationUser: {
        ProviderAttributeValue: username,
        ProviderName: 'Cognito'
      },
      SourceUser: {
        ProviderAttributeName: 'Cognito_Subject',
        ProviderAttributeValue: response.googleId,
        ProviderName: providerName
      },
      UserPoolId: userPoolId
    }
    params.type = 'ALPU';

    CommonService.portalApiCall("rest/linkprovider", params, localStorage.getItem(AUTH_USER_TOKEN_KEY)
    ).then((resp:any) => {
      const prm: any = {
        UserAttributes: [
          {
            Name: 'custom:socialIdentities',
            Value: JSON.stringify([{
              id: response.googleId,
              provider: "Google",
              email: response.profileObj.email,
              name: response.profileObj.name
            }])
          }
        ],
        UserPoolId: poolCongig.userPoolId,
        Username: username
      };
      prm.type = 'AUUA';
      CommonService.portalApiCall("rest/linkprovider", prm, localStorage.getItem(AUTH_USER_TOKEN_KEY)
      ).then((response:any) => {
        customAuth(username);
      });
    })
  }

  /**
   * This method enables the user to sign in with google account that already linked with the
   * user account or google email is same as the user account email.
   * @param {object} response Google profile object
   */
  

  const signInWithGoogle = (response: any) => {
    const userObject = jwtDecode(response.credential);
    
    if (userObject) {
      setSocialLoading(true);
      const params = {
        providerUserId: userObject.sub,
        emailId: userObject.email
      };
      CommonService.portalApiCall("rest/filteruser", params
      ).then((resp:any) => {
        if (resp.data.errorCode === 0) {
          if (resp.data.customData) {
            if (resp.data.customData.isLinked === 'Y') {
              customAuth(resp.data.customData.username);
            } else if (resp.data.customData.isLinked === 'N') {
              let customresponse = {
                googleId:userObject.sub,
                profileObj:{
                  email:userObject.email,
                  name:userObject.name
                }
              };
              linkProviderToUser(resp.data.customData.username, poolCongig.userPoolId, "Google", userObject.email, customresponse);
            } else if (resp.data.customData.isLinked === '') {
              setSocialLoading(false);
              notification.error({
                message: 'Error',
                description: "User Not Found",
                placement: 'topRight'
              });
            }
          } else {
            setSocialLoading(false);
            notification.error({
              message: 'Error',
              description: "User Not Found",
              placement: 'topRight'
            });
          }
        } else {
          setSocialLoading(false);
          notification.error({
            message: 'Error',
            description: "User Not Found",
            placement: 'topRight'
          });
        }
      })
      }
    //getUserByEmail(poolCongig.userPoolId, response.googleId, response.profileObj.email, response);
  }

  const generateForumUrl = (user: any, newLogin?:boolean) => {
    const params: any = {
      "userId": user.attributes["custom:kpmUserId"],
      "userName": user.attributes.given_name + " " + user.attributes.family_name,
      "sig": query.sig,
      "emailId": user.attributes.email,
      "sso": query.sso
    }
    CommonService.postApiExecutor("authenticateforumsso",
      params, user.signInUserSession.accessToken.jwtToken).then((response:any) => {
        if (response.data.errorCode === 0) {
          if(newLogin) {
            window.location.assign(process.env.REACT_APP_FORUM_URL as any);
          } else {
            window.location.assign(response.data.customData);
          }
        } else {
          if (response.data.description != null) {
            notification.error({
              message: 'Error',
              description: response.data.description,
              placement: 'topRight'
            });
          }
        }
      }).catch((err:any) => {
        notification.error({
          message: 'Error',
          description: err.message,
          placement: 'topRight'
        });
      });
  }
  const handlePasswodView = ()=>{
    setShowPassword((toggleClass) => !toggleClass);
  }

  return (checkUserAuth && query.site) ? null : (
    <div className="login-main-wrapper">
      <ReCAPTCHA
        ref={recaptchaRef as any}
        size="invisible"
        sitekey={reCapKey as any}
      />
      <div className="loginGradientBackground">
        <header className="login-header">
          <div className="row">
            <div className="col-sm-6">
              <img src={require('../../assets/images/konnexsion-logo.png')} alt="" />
            </div>
            <div className="col-sm-6 text-right">
              {/* <p className="text-right donthaveaccount">Don't have an account? 
                  <Link to="/signup" className="singupaction"> Sign up</Link>
              </p> */}
            </div>
          </div>
        </header>
        <div className="loginMainContain">
          {requestMgl && 
            <React.Fragment>
                <div className="mglLoading">
                  <p>Please wait</p>
                  <span>
                    <i></i>
                    <i></i>
                    <i></i>
                  </span>
                </div>
            </React.Fragment>
          }
          {currentState === "MGLS" && (
              <React.Fragment>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10">
                                <div className="mglnkContainWidth">
                                    <img src={require('../../assets/images/email.png')} alt="" className='mglsuccessEmail'/>
                                    <div className="mgslSuccessBody">
                                        <h2>An email is on its way!</h2>
                                        <p className='mbp'>
                                          We sent an email to <strong>{mglemail}</strong>.
                                        </p>
                                        <p>
                                          If this email address has an account with <strong>Konnexsion</strong>, you will find a magic link that will sign you in.
                                        </p>
                                        <h2>Go check your email!</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
              </React.Fragment>
          )}
          {currentState === "LOGIN" && (
            <React.Fragment>
              <div className="container">
                <div className="row">
                  <div className="col-sm-5">
                    <div className="loginContainWidth">
                      <div className="row">
                        <div className="col-sm-12 sign-in-left">
                          <h3 className="singInyourAccount">Sign in to your account</h3>
                        </div>
                        <div className="col-sm-12 ">

                          <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-group absoluteMobileWrap">
                              <label className='hideLabel'>Email Address</label>
                              <i className="fa fa-envelope icon"></i>
                              <input
                                type="email"
                                name="username"
                                className="form-control custom-mobile-border"
                                placeholder="Enter email"
                                ref={register({
                                  required: true,
                                  validate: (input: any) => isEmail(input),
                                })}
                              />
                              {errors.username && errors.username.type === "required" && (
                                <div className="error">Your must enter your email address.</div>
                              )}
                            </div>

                            <div className="form-group password-form absoluteMobileWrap">
                              <label className='hideLabel'>Password</label>
                              <i className="fa fa-key icon"></i>
                              <i className={`fa passIcon ${toggleClassCheck}`} onClick={handlePasswodView}></i>
                              
                              <input
                                type={`${passwordType}`}
                                className="form-control custom-mobile-border"
                                placeholder="Enter password"
                                name="password"
                                ref={register({
                                  required: true,
                                  minLength: 6,
                                })}
                              />
                              {errors.password && errors.password.type === "required" && (
                                <div className="error">Your must enter your password.</div>
                              )}
                            </div>

                            {!deviceKeyExist && (
                              <div className="form-group absoluteMobileWrap">
                                <label className='hideLabel'>New Device Detected</label>
                                <i className="fa fa-envelope icon"></i>
                                <input
                                  type="text"
                                  name="devicename"
                                  className="form-control custom-mobile-border"
                                  placeholder="Enter a Friendly Device Name"
                                  autoComplete='off'
                                  ref={register({
                                    required: false
                                  })}
                                />
                              </div>
                            )}


                            <button type="submit" className="btn btn-primary btn-block btn-login" disabled={isSubmitting}>
                              {isSubmitting && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                              Submit
                            </button>
                            <p className="orborderandtx m-b-10">
                              <span>OR</span>


                            </p>

                            <div className="sign-in-socialmedia">
                              {/* <GoogleLogin
                                clientId={gClientId as string}
                                buttonText="Continue with google"
                                onSuccess={(data) => signInWithGoogle(data)}
                                onFailure={(err) => signInWithGoogle(err)}
                                cookiePolicy={'single_host_origin'}
                              >
                                {socialLoading && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                              </GoogleLogin> */}
                              <GoogleOAuthProvider clientId={gClientId as string}>
                              <GoogleLogin
                                  onSuccess={credentialResponse => {
                                    signInWithGoogle(credentialResponse);
                                  }}
                                  onError={() => {
                                    console.log('Login Failed');
                                  }}
                                />
                              </GoogleOAuthProvider>
                            </div>
                          </form>
                            <p className="orborderandtx">
                              <span>OR</span>
                            </p>
                            <div className="mt-3">
                                
                                  <button type="button" className="btn btn-primary btn-block btn-login" disabled={isSubmittingMgl} onClick={()=>{sendMagicLink()}}>
                                        {isSubmittingMgl && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                                        Login with a Magic Link 
                                        <img src={require('../../assets/images/magic-13.svg')} alt="" className='magic-link-icon'/>
                                  </button>
                                  {magiclinkerr && (
                                      <div className="error">{magiclinkerr}</div>
                                  )}
                            </div>
                            <p className="forgot-password text-left">
                              <Link to="/forgot-password"> Forgot password?</Link>
                            </p>
                          
                        </div>
                      </div>
                    </div>




                  </div>
                  <div className="col-sm-7 login-right-text">
                    <h2>{pageConf.title_1}</h2>
                    <h4>{pageConf.title_2}</h4>
                    <p>
                      <a className="EnquireToday" href={pageConf.enquire_url}>
                        {pageConf.enquire_text}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {currentState === "NF" && (
            <div className="loginMainContain">
              <div className="container">
                <div className="row">
                  <div className="col-sm-5">
                    <div className="loginContainWidth">
                      <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} onClick={() => sendAgain()}>
                        {isSubmitting && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
          Resend Activation
            </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentState === "SELECT_MFA_TYPE" && mfaParam.MFAS_CAN_CHOOSE && (
            
                <Modal show={openModal} onHide={() => setOpenModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>
                  Verify your Identity
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-sm-12 ">
                    {JSON.parse(mfaParam.MFAS_CAN_CHOOSE).map((val: any, i: any) => (
                      <label key={`mfa_type_${i}`}>
                        <input type="radio"
                          value={val}
                          checked={choosenMFA === val}
                          onChange={() => setChoosenMFA(val)}
                        />
                        {val === "SMS_MFA" ? "SMS" : "Software Token"}
                      </label>
                    ))}
                  </div>
                  <div className="col-sm-12 ">
                    <button className="btn btn-primary updatePasword" disabled={choosenMFA === ""}
                      onClick={() => setMFAResponse()}>Continue
                      </button>
                  </div>
                </div>
              </Modal.Body>
              </Modal>
            
              
            
          )
          }
          {(currentState === "SMS_MFA" || currentState === "SOFTWARE_TOKEN_MFA") && (
            <div className="loginMainContain">
              <div className="container">
                <div className="row">
                  <div className="col-sm-5">
                    <div className="loginContainWidth">

                      <form onSubmit={handleSubmit(onMFA)}>
                        <div className="form-group">
                          {currentState === "SOFTWARE_TOKEN_MFA" && <p className="forgotemailAddress text-left">
                            Please Enter the code from {mfaParam.FRIENDLY_DEVICE_NAME}
                          </p>}

                          {currentState === "SMS_MFA" && <p className="forgotemailAddress text-left">
                            we have delivered the authentication code by SMS to {mfaParam.CODE_DELIVERY_DESTINATION}.Please enter the code to
                 complete authentication
                </p>}

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Verification Code"
                            name="mfa"
                            ref={register({
                              required: true,
                              minLength: 4,
                            })}
                          />
                          {errors.mfa && errors.mfa.type === "required" && (
                            <div className="error">Your must enter your code.</div>
                          )}
                          <button type="submit" className="btn btn-primary btn-block m-t-10" disabled={isSubmitting}>
                            {isSubmitting && (<span className="spinner-border spinner-border-sm m-r-5" role="status" aria-hidden="true"></span>)}
            Submit
          </button>
                        </div>
                      </form>


                    </div>
                  </div>

                  <div className="col-sm-7 login-right-text"><h2>Manage everything on one platform</h2><h4>For letting agents, property management companies, and landlords</h4><p><a className="EnquireToday" href="https://www.kpm.one">Enquire Today</a></p></div>
                </div>


              </div>




            </div>
          )
          }
          {currentState === "NPR" && (
            <div className="loginMainContain">

              <div className="container">
                <div className="row">
                  <div className="col-sm-5">
                    <div className="loginContainWidth">


                      <form onSubmit={handleSubmit(onNewPass)}>
                        <div className="form-group">
                          <p className="forgotemailAddress text-left">New Password</p>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Enter New Password"
                            name="nps"
                            ref={register({
                              required: true,
                              minLength: 8,
                            })}
                          />
                          {errors.nps && errors.nps.type === "required" && (
                            <div className="error">Your must enter your password.</div>
                          )}
                          <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                            {isSubmitting && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
            Submit
          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-sm-7 login-right-text"><h2>Manage everything on one platform</h2><h4>For letting agents, property management companies, and landlords</h4><p><a className="EnquireToday" href="https://www.kpm.one">Enquire Today</a></p></div>
                </div>
              </div>




            </div>
          )
          }
        </div>
      </div>
      <div  className="login-footer-msg text-center ">

Copyright © {currentYear} Konnexsion Property Ltd. All rights reserved.<a href={licenceAgreementUrl} target="_blank">Licence Agreement</a>
</div>
    </div>
  );
}

export default LoginContainer;
