import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AddPhoneNumber from '../../../Directives/AddPhoneNumber';
import GPlace from '../../../Directives/PlaceApi';

const Profile = (props: any) => {
  return (
    <div className="row">
      <div className="col-sm-9">
        <form>
          <div className="form-group row">
            <div className="col-sm-3">
              <label htmlFor="inputFirstname">Title</label>
              <input
                type="text"
                className="form-control"
                id="inputFirstname"
                placeholder="Title"
                value={(props.konProfile && props.konProfile.title) ? props.konProfile.title : ""}
                onChange={event => props.handleKonInputChange('given_name', event.target.value)}
              />
            </div>
            <div className="col-sm-3">
              <label htmlFor="inputFirstname">First name</label>
              <input
                type="text"
                className="form-control"
                id="inputFirstname"
                placeholder="First name"
                value={props.konProfile ? props.konProfile.firstName : ""}
                onChange={event => props.handleKonInputChange('firstName', event.target.value)}
              />
            </div>
            <div className="col-sm-3">
              <label htmlFor="inputFirstname">Middle Name</label>
              <input
                type="text"
                className="form-control"
                id="inputFirstname"
                placeholder="Middle Name"
                value={props.konProfile && props.konProfile.middleName ? props.konProfile.middleName : ""}
                onChange={event => props.handleKonInputChange('middleName', event.target.value)}
              />
            </div>
            <div className="col-sm-3">
              <label htmlFor="inputLastname">Last name</label>
              <input
                type="text"
                className="form-control"
                id="inputLastname"
                placeholder="Last name"
                value={props.konProfile ? props.konProfile.lastName : ""}
                onChange={event => props.handleKonInputChange('lastName', event.target.value)}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-6 search-locaton ">
              {/*  <label htmlFor="inputAddressLine1">Search</label> */}
              {props.loadMap &&
                <GPlace placeChanged={data => props.placeChanged(data)} />
              }
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12">
              <label htmlFor="inputAddressLine1">Street</label>
              <textarea
                className="form-control"
                id="inputAddressLine1"
                placeholder="Street Address"
                value={(props.placeObj && props.placeObj.streetName)  ? props.placeObj.streetName : ""}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-3">
              <label htmlFor="inputCity">City</label>
              <input
                type="text"
                className="form-control"
                id="inputCity"
                placeholder="City"
                value={props.placeObj ? props.placeObj.city : ""}
              />
            </div>
            <div className="col-sm-3">
              <label htmlFor="inputState">County</label>
              <input
                type="text"
                className="form-control"
                id="inputState"
                placeholder="County"
                value={props.placeObj ? props.placeObj.county : ""}
              />
            </div>
            <div className="col-sm-3">
              <label htmlFor="inputPostalCode">Postal Code</label>
              <input
                type="text"
                className="form-control"
                id="inputPostalCode"
                placeholder="Postal Code"
                value={props.placeObj ? props.placeObj.postCode : ""}
              />
            </div>
            <div className="col-sm-3">
              <label htmlFor="inputPostalCode">Country</label>
              <input
                type="text"
                className="form-control"
                id="inputPostalCode"
                placeholder="Country"
                value={props.placeObj ? props.placeObj.country : ""}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div className="phoneWrapper">
                <label htmlFor="inputWebsite">Phone Number</label>
                {[...Array(props.phoneCounter)].map((x, i) =>

                  <div className="row" key={`phone_${i}`}>
                    <div className="col-sm-12 col-lg-6">
                      <AddPhoneNumber
                        placeholder="Enter phone number"
                        defaultCountry={props.defaultCountry}
                        value={props.phoneArray[i].contactNumber}
                        onChange={(data: any) => props.phoneCahnge(data, i)} />

                    </div>
                    <div className="col-sm-12 col-lg-5">

                      <Select
                        options={props.contactTypeDropDown}
                        getOptionLabel={(option: any) => option.description}
                        getOptionValue={(option: any) => option.detailId}
                        onChange={(data: any) => props.onChangeType(data)}
                        value={props.selectedType}
                      />


                    </div>
                    <div className="col-sm-12 col-lg-1">
                    {i> 0 && <button className="addBtnPhoneNo addBtnPhoneNoRemove" onClick={(event:any) => props.removePhone(event)}>-</button>}</div>
                  </div>


                )}
                <button className="addBtnPhoneNo" onClick={(event:any) => props.addnewPhone(event)}>+</button>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <label htmlFor="inputContactNumber">Email</label>
              <input
                disabled
                type="email"
                className="form-control"
                id="inputCEmail"
                placeholder="Email"
                defaultValue={props.konProfile ? props.konProfile.emailId : ""}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="col-sm-3">
        <span className="profile-background-style">
          <button className="profileuploadAction uploadPosition " onClick={()=> props.doUpload()}>
            <i className="la la-upload" aria-hidden="true"></i>
          </button>
          <button className="profileuploadAction removeBtnPostion" onClick={()=> props.removePhoto()}>
            <i className="la la-trash" aria-hidden="true"></i>
          </button>


          {props.konProfile && props.konProfile.profile_pic && (<img style={{ height: "100px", width: "100px" }} alt="" src={props.konProfile.profile_pic} />)}
          {(!props.konProfile || (props.konProfile && !props.konProfile.profile_pic)) && (<img src={require('../../../assets/images/place-holder-profile.jpg')} alt="" />)}
        </span>
        <input
          type="file"
          hidden
          className="form-control"
          id="inputFile"
          placeholder="Profile Photo"
          defaultValue={props.profile ? props.profile.profile : ""}
          onChange={(event: any) => props.onFileChange(event.target.files[0])}
        />
      </div>
    </div>
  )
}

Profile.propTypes = {
  konProfile: PropTypes.object,
  onFileChange: PropTypes.func,
  phoneCounter: PropTypes.number,
  phoneArray: PropTypes.array,
  phoneCahnge: PropTypes.func,
  addnewPhone: PropTypes.func,
  removePhone: PropTypes.func,
  removePhoto: PropTypes.func,
  placeChanged: PropTypes.func,
  placeObj: PropTypes.object,
  handleKonInputChange: PropTypes.func,
  doUpload: PropTypes.func,
  loadMap: PropTypes.bool,
  contactTypeDropDown: PropTypes.array,
  onChangeType: PropTypes.func,
  selectedType: PropTypes.object,
  defaultCountry: PropTypes.string
}

export default Profile;