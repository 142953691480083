import React,{useState, useRef, useEffect} from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import axios from 'axios';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

import { AutoComplete, notification } from 'antd';
import { subscriptiontermUrl } from '../../../constants';
import CommonService from '../../../Services/CommonService';
import { AUTH_USER_TOKEN_KEY } from '../../../Utils/constants';

type Entity = {
  type: number,
  firstName:string,
  lastName:string,
  emailId:string,
  companyName:string,
  radioplan:string,
  customplan:string,
  password:string,
  affiliateRefId:string,
  crmUserId:string,
  trialDays:string
};

let initNumber:any

const SubscriptionForm = (props: any) => {

  const { register, handleSubmit, errors, reset, setValue} = useForm<Entity>();
  const [entityType,setEntityType] = useState(84);
  const [showTerms, setShowTerms] = useState(false);
  const [savingEntity,setSavingEntity] = useState(false);
  const [loadingData, setloadingData] = useState(true);
  const [planDataFetched, setplanDataFetched] = useState(false);
  const [planData,setPlanData] = useState([]);
  const [showPlanDetails, setShowPlanDetails] = useState(false);
  const [customplancode, setCustomplancode] = useState('');
  const [passwordmode, setPasswordmode] = useState('password');
  const [phoneNumber, setPhoneNumber] = useState(initNumber);
  const [emailAvailable, setEmailAvailable] = useState(false);
  const [checkingAvl, setcheckingAvl] = useState(false);
  const [populatedCustomPlan, setpopulatedCustomPlan] = useState(false);
  
  
  const handleCloseTerms = () => setShowTerms(false);
  const handleClosePlanDetails = () => setShowPlanDetails(false);
  const iframeRef:any = useRef<HTMLDivElement>(null);
  const iframePlanRef:any = useRef<HTMLDivElement>(null);


  const handleshowTerms = () => {
      setShowTerms(true);
      setTimeout(() => {
        iframeRef.current.innerHTML = '<iframe  src="' + subscriptiontermUrl + '" height="100%" width="100%" ></iframe>';
      }, 30);
  };

  const handleViewDetails = (url:any)=>{
    setShowPlanDetails(true);
    setTimeout(() => {
      iframePlanRef.current.innerHTML = '<iframe  src="' + url + '" height="100%" width="100%" ></iframe>';
    }, 30);
  }

  const handleTermsResponse = (type:any)=>{
    props.setAgreedToTerms(type);
    handleCloseTerms();
  }

  const checkEmailAvailability = (emailId:any)=>{
    if(props.isLoginForm === 'Y'){
        setcheckingAvl(true);
        let params = {
          emailId:emailId
        }

        CommonService.postApiExecutor("rest/user/checkemailavailability", params,localStorage.getItem(AUTH_USER_TOKEN_KEY)
          ).then(response => {
            setcheckingAvl(false);
            if (response.data.errorCode === 1) {
              setEmailAvailable(false);
              notification.error({
                message: 'Error',
                description: response.data.description,
                placement: 'topRight'
              })
            } else {
              setEmailAvailable(true);
            }
          },
          err => {
      
          })
      }
  }
  
  const fetchplanlist = (emailId:any)=>{
    if(!emailId){
      return;
    }
    checkEmailAvailability(emailId);
    setloadingData(true);
    let params = {
      emailId:emailId,
      type:'new_account'
    }
    CommonService.postApiExecutor("rest/fetchplanlist", params,localStorage.getItem(AUTH_USER_TOKEN_KEY)
      ).then(response => {
        setloadingData(false);
        if (response.data.errorCode === 1) {
          setPlanData([]);
          notification.error({
            message: 'Error',
            description: response.data.description,
            placement: 'topRight'
          })
        } else {
          let data = response.data.customData;
          if(data){
            data = data.map(function(val:any, i:any){
              return {...val,plan_details:val.plan_details?JSON.parse(val.plan_details.value):val.plan_details} 
            })
            setPlanData(data);
          }
          setplanDataFetched(true);
        }
      },
      err => {
  
      })
  }
  
  useEffect(()=>{
    if(props.isLoginForm === 'N'){
      fetchplanlist(props.entityDetails.emailId);
    }
  },[]);


  if(props.location){
    let queryString = props.location.search;
    const queryParamsArr = new URLSearchParams(queryString);

    setTimeout(() => {
      if(queryParamsArr.get('FirstName') && queryParamsArr.get('fname')){
        setValue('firstName',queryParamsArr.get('fname'));
      } else {
        if(queryParamsArr.get('FirstName')){
          setValue('firstName',queryParamsArr.get('FirstName'));
        } else if(queryParamsArr.get('fname')){
          setValue('firstName',queryParamsArr.get('fname'));
        }
      }

      if(queryParamsArr.get('LastName') && queryParamsArr.get('lname')){
        setValue('lastName',queryParamsArr.get('lname'));
      } else {
        if(queryParamsArr.get('LastName')){
          setValue('lastName',queryParamsArr.get('LastName'));
        } else if(queryParamsArr.get('lname')){
          setValue('lastName',queryParamsArr.get('lname'));
        }
      }

      if(queryParamsArr.get('email') && queryParamsArr.get('Email')){
        setValue('emailId',queryParamsArr.get('email'));
      } else {
        if(queryParamsArr.get('Email')){
          setValue('emailId',queryParamsArr.get('Email'));
        } else if(queryParamsArr.get('email')){
          setValue('emailId',queryParamsArr.get('email'));
        }
      }

      if(queryParamsArr.get('etype')){
        setValue('type',queryParamsArr.get('etype'));
      }
      
      if(queryParamsArr.get('cname') && queryParamsArr.get('Company')){
        setValue('companyName',queryParamsArr.get('cname'));
      } else {
        if(queryParamsArr.get('Company')){
          setValue('companyName',queryParamsArr.get('Company'));
        } else if(queryParamsArr.get('cname')){
          setValue('companyName',queryParamsArr.get('cname'));
        }
      }

      if(queryParamsArr.get('a_ref_id') && queryParamsArr.get('AffiliateRef')){
        setValue('affiliateRefId',queryParamsArr.get('a_ref_id'));
      } else {
        if(queryParamsArr.get('AffiliateRef')){
          setValue('affiliateRefId',queryParamsArr.get('AffiliateRef'));
        } else if(queryParamsArr.get('a_ref_id')){
          setValue('affiliateRefId',queryParamsArr.get('a_ref_id'));
        }
      }

      if(queryParamsArr.get('c_id') && queryParamsArr.get('Contact.Id')){
        setValue('crmUserId',queryParamsArr.get('c_id'));
      } else {
        if(queryParamsArr.get('Contact.Id')){
          setValue('crmUserId',queryParamsArr.get('Contact.Id'));
        } else if(queryParamsArr.get('c_id')){
          setValue('crmUserId',queryParamsArr.get('c_id'));
        }
      }

      if(queryParamsArr.get('t_days')){
        setValue('trialDays',queryParamsArr.get('t_days'));
      }

      if(queryParamsArr.get('phone')){
        let phoneNum:any = queryParamsArr.get('phone')?queryParamsArr.get('phone')?.replace(' ','+'):'';
        setPhoneNumber(phoneNum);
      }

      if(queryParamsArr.get('blc')){
        
        setValue('radioplan','-1');
        setValue('customplan',queryParamsArr.get('blc'));
        setpopulatedCustomPlan(true);
      }

    }, 50);
  }
  
  
  
  const saveSubscription = (event: Entity)=>{
    if(props.isLoginForm === 'N'){
      setSavingEntity(true);
      let params = {
        "emailId": event.emailId,
        "firstName": event.firstName,
        "lastName": event.lastName,
        "entityCategory": 86,
        "createEntity": 1,
        "entityType": event.type,
        "companyName": (event.type == 84 || event.type == 16578)?event.companyName:'' ,
        "offerCode": (event.radioplan !== '-1')?event.radioplan?event.radioplan:'':event.customplan?event.customplan:''
      };
      //${process.env.REACT_APP_KPM_API}
      //https://api.kpmdev.one
      axios.post(`${process.env.REACT_APP_KPM_API}/rest/user/registeruser`, params,{timeout:120000}).then(response => {
          if (response.data.errorCode === 1) {
            setSavingEntity(false);
            notification.error({
              message: 'Error',
              description: response.data.description,
              placement: 'topRight'
            })
          } else {
            notification.success({
              message: 'Succes',
              description: response.data.description,
              placement: 'topRight',
              duration: 1.5
            });
            setSavingEntity(false);
            setCustomplancode('');
            props.fetchSubscriptionList();
            props.closeSubscriptionModal();
          }
        },
        err => {
    
      });
    } else if(props.isLoginForm === 'Y'){ 

        if(phoneNumber && !isValidPhoneNumber(phoneNumber)){
          return;
        }
        if(checkingAvl || !emailAvailable){
          return;
        }
        setSavingEntity(true);
        let params = {
          "emailId": event.emailId,
          "firstName": event.firstName,
          "lastName": event.lastName,
          "entityCategory": 86,
          "entityType": event.type,
          "companyName": (event.type == 84 || event.type == 16578)?event.companyName:'' ,
          "offerCode": (event.radioplan !== '-1')?event.radioplan?event.radioplan:'':event.customplan?event.customplan:'',
          "password":event.password,
          "mobileNumber":phoneNumber,
          "affiliateRefId":event.affiliateRefId?event.affiliateRefId:null,
          "crmUserId":event.crmUserId?event.crmUserId:null,
          "trialDays":event.trialDays?event.trialDays:null
        };

        axios.post(`${process.env.REACT_APP_KPM_API}/rest/registeruser`, params,{timeout:120000}).then(response => {
          if (response.data.errorCode === 1) {
            setSavingEntity(false);
            notification.error({
              message: 'Error',
              description: response.data.description,
              placement: 'topRight'
            })
          } else {
            notification.success({
              message: 'Succes',
              description: 'Registration Successful',//response.data.description,
              placement: 'topRight',
              duration: 1.5
            });
            setSavingEntity(false);
            setCustomplancode('');
            props.setSignupData(response.data.customData);
            props.setSignupComplete(true);
            reset({
              firstName:'',
              lastName:'',
              type:84,
              emailId:'',
              companyName:'',
              password:'',
              radioplan:'',
              customplan:''
            });
            setPhoneNumber('');
          }
        },
        err => {
    
      });
    }
  }


  return (
    <>
      <form className="form-horizontal" onSubmit={handleSubmit(saveSubscription)}>
        <div className="form-group">
        <div className="col-sm-12">
          <div className="row">
              <div className="col-sm-12">
                  <span className="spnStyl">Entity Type</span>

                  <label className="customChkBox customradioCheck labelLeftMargin" htmlFor="radio1">  Individual
                  <input id="radio1" type="radio" name="type" value="85" checked={entityType === 85} onChange={()=>{setEntityType(85)}} ref={register({
                        required: true
                      })}/>
                   <span className="checkmark"></span>
                   </label>

            {/*   <div className="radio radio-primary radio-inline radioLineht mrgLft">
                   
                    <label >
                     
                    </label>
                  </div> */}

                       <label className="customChkBox customradioCheck labelLeftMargin" htmlFor="radio2">  Company
                       <input id="radio2" type="radio" name="type" value="84" checked={entityType === 84} onChange={()=>{setEntityType(84)}} ref={register({
                        required: true
                      })}/>
                   <span className="checkmark"></span>
                   </label>

              {/*     <div className="radio radio-primary radio-inline radioLineht mrgLft">
                   
                    <label htmlFor="radio2">
                      
                    </label>
                  </div> */}

                   <label className="customChkBox customradioCheck labelLeftMargin" htmlFor="radio3">  Partnership
                        <input id="radio3" type="radio" name="type" value="16578" checked={entityType === 16578} onChange={()=>{setEntityType(16578)}} ref={register({
                        required: true 
                      })}/>
                   <span className="checkmark"></span>
                   </label>

               {/*    <div className="radio radio-primary radio-inline radioLineht mrgLft">
                   
                    <label htmlFor="radio3">
                      Partnership
                    </label>
                  </div> */}
              </div>
          </div>

          <div className="row p-t-10">
              <div className="col-sm-6">
                <label>First Name <span className="required">*</span></label>
                <div>
                  {(props.isLoginForm === 'N' && props.entityDetails) &&
                      <input type="text" name="firstName" id="firstName" readOnly={true} className="form-control pull-left" value={props.entityDetails.firstName} ref={register({
                        required: true
                      })}/>
                  }
                  {(props.isLoginForm === 'Y') &&
                      <input type="text" name="firstName" id="firstName" readOnly={savingEntity} className="form-control pull-left" ref={register({
                        required: true
                      })}/>
                  }
                  <div className="log-icon pull-right"><i className="fa fa-user-o"></i></div>

                  <div>
                    {errors.firstName && errors.firstName.type === "required" && (
                      <div className="m-l-10" style={{color:'#e74c3c'}}>Please enter first name</div>
                    )}
                  </div>
                </div>

              </div>
              <div className="col-sm-6">

                <label>Last Name <span className="required">*</span></label>
                <div>
                    {(props.isLoginForm === 'N' && props.entityDetails) &&
                      <input type="text" name="lastName" id="lastName" readOnly={true}  className="form-control pull-left" value={props.entityDetails.lastName} ref={register({
                        required: true
                      })}/>
                    }
                    {(props.isLoginForm === 'Y') &&
                        <input type="text" name="lastName" id="lastName" readOnly={savingEntity} className="form-control pull-left" ref={register({
                          required: true
                        })}/>
                    }
                  <div className="log-icon pull-right"><i className="fa fa-user-o"></i></div>

                  <div>
                    {errors.lastName && errors.lastName.type === "required" && (
                      <div className="m-l-10" style={{color:'#e74c3c'}}>Please enter last name</div>
                    )}
                  </div>
                </div>
              </div>
          </div>

          <div className="row p-t-10">
            <div className="col-sm-6">
              <div className="has-feedback">
                <label>Email <span className="required">*</span></label>
                <div>
                    {(props.isLoginForm === 'N' && props.entityDetails) &&
                      <input type="email" id="email" name="emailId" className="form-control pull-left" readOnly={true} value={props.entityDetails.emailId} ref={register({
                        required: "Please enter email address"
                      })} />
                    }
                    {(props.isLoginForm === 'Y') &&
                      <input type="text" id="email" name="emailId" readOnly={savingEntity} className="form-control pull-left" onBlur={(e)=>fetchplanlist(e.target.value)} ref={register({
                        required: "Please enter email address",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Please enter a valid email address"
                        }
                      })} />
                    }

                  <div className="log-icon pull-right">
                    {!checkingAvl && (<i className="fa fa-envelope-o"></i>)}
                    {checkingAvl && (<i className="fa fa-circle-o-notch fa-spin"></i>)}
                  </div>

                  <div>
                    {errors.emailId?.message && (
                      <div className="m-l-10" style={{color:'#e74c3c'}}>{errors.emailId?.message}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {(entityType === 84 || entityType === 16578) && (
              <div className="col-sm-6">
                <div className="has-feedback">

                  <label>Company Name <span className="required">*</span></label>
                  <div>
                    <input 
                    type="text" 
                    id="companyName" 
                    name="companyName" 
                    readOnly={savingEntity}
                    className="form-control pull-left"  
                    value={props.entityDetails.companyName}  
                    ref={register({
                        required: true
                      })}/>
                    <div className="log-icon pull-right"><i className="fa fa-building-o"></i></div>

                    <div>
                      {errors.companyName && errors.companyName.type === "required" && (
                        <div style={{color:'#e74c3c'}}>Please enter company name.</div>
                      )}
                    </div>

                  </div>
                </div>
              </div>
            )}

            {(!(entityType === 84 || entityType === 16578) && props.isLoginForm === 'Y') &&
            (<div className="col-sm-6">
            <div className="has-feedback">
                <label>Phone number</label>

                <div>
                    <PhoneInput
                        placeholder=""
                        defaultCountry='GB'
                        value={phoneNumber}
                        onChange={(data:any) =>setPhoneNumber(data)}
                    />
                    {phoneNumber && !isValidPhoneNumber(phoneNumber) && (<div className="m-l-10" style={{color:'#e74c3c'}}>Invalid Phone Number</div>)}
                </div>
            </div>
            </div>)}
          </div>

          {props.isLoginForm === 'Y' && 
          (<div className="row p-t-10">
              {((entityType === 84 || entityType === 16578)) &&
              (
                <div className="col-sm-6">
                <div className="has-feedback">
                    <label>Phone number</label>

                    <div>
                        <PhoneInput
                            placeholder=""
                            defaultCountry='GB'
                            value={phoneNumber}
                            onChange={(data:any) =>setPhoneNumber(data)}
                        />
                        {phoneNumber && !isValidPhoneNumber(phoneNumber) && (<div className="m-l-10" style={{color:'#e74c3c'}}>Invalid Phone Number</div>)}
                    </div>
                </div>
                </div>
              )}

              <div className="col-sm-6">
              <div className="has-feedback">

              <label>Password <span className="required">*</span></label>
              <div>
                <input 
                readOnly={savingEntity}
                type={passwordmode} 
                id="password" 
                name="password" 
                className="form-control pull-left" 
                defaultValue = ""
                ref={register({
                    required: "Please enter password",
                    minLength:{
                      value: 8,
                      message: "Password must have least 8 characters"
                    },
                    maxLength:{
                      value: 15,
                      message: "Maximum 15 characters are allowed"
                    },
                    pattern: {
                      value: /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,15}$/,
                      message: "Password must contain at least one uppercase one lowercase and one special character"
                    }
                  })}/>
                <div className="log-icon pull-right" >
                  {(passwordmode == 'password')&&(<i className="fa fa-eye" onClick={()=>setPasswordmode('text')}></i>)}
                  {(passwordmode == 'text')&&(<i className="fa fa-eye-slash" onClick={()=>setPasswordmode('password')}></i>)}
                </div>

                <div>
                    {errors.password?.message && (
                      <div className="m-l-10" style={{color:'#e74c3c'}}>{errors.password?.message}</div>
                    )}
                </div>

              </div>
            </div>
            </div>

          </div>)}
          
          <div className="row p-t-10">
            <div className="col-sm-12"><h5 className="selectPlan">Select Plan</h5></div>
            
            <div className="col-sm-12">
                {!populatedCustomPlan && planDataFetched && planData.map((plandata:any)=>
                (<div className="row subscriptionRow" key={plandata.plan_id}>
                <div className="col-sm-6">
                <label className="customChkBox customradioCheck" htmlFor={plandata.plan_id}>{plandata.plan_description}
                <input id={plandata.plan_id} type="radio" name="radioplan" ref={register({
                        required: false
                      })} value={plandata.billing_code} onChange={()=>setCustomplancode('')}/>
                  <span className="checkmark"></span>
                </label>

               {/*      <div className="radio radio-primary radio-inline radioLineht mrgLft">
                      <input id="radioplan{plandata.plan_id}" type="radio" name="radioplan" ref={register({
                        required: false
                      })} value={plandata.billing_code} onChange={()=>setCustomplancode('')}/>
                      <label htmlFor="radioplan{plandata.plan_id}">
                          {plandata.plan_description}
                      </label>
                    </div> */}
                </div>
                <div className="col-sm-2">
                  {plandata.plan_details && plandata.plan_details.view_details && <a href="javascript:void(0)" className="underlineTx" onClick={() => handleViewDetails(plandata.plan_details.view_details)}> View Details </a> }
                </div>
                <div className="col-sm-4 plandataTx">
                  {plandata.plan_details && plandata.plan_details.price}
                </div>
                </div>))}

                <div className="row subscriptionRow">


                    <div className="col-sm-6">
                    <label className="customChkBox customradioCheck" htmlFor="radioplancustom"> I have a Custom Plan Code
                    <input id="radioplancustom" type="radio" name="radioplan" ref={register({
                        required: false
                      })} value='-1' readOnly={populatedCustomPlan}/> 
                  <span className="checkmark"></span>
                </label>



                      {/*   <div className="radio radio-primary radio-inline radioLineht mrgLft">
                          
                          <label >
                             
                          </label>
                        </div> */}
                    </div>

                    <div className="col-sm-3">
                        <input type="text" id="customplan" name="customplan" className="form-control pull-left" value={customplancode} readOnly={populatedCustomPlan || savingEntity} onChange={(e)=>setCustomplancode(e.target.value)} ref={register({
                        required: false
                      })} />
                    </div>    
                </div>
            </div>

            <div className="col-sm-12">
                {errors.radioplan && errors.radioplan.type === "required" && (
                  <div className="m-l-20" style={{color:'#e74c3c'}}>Please select plan.</div>
                )}
                    
            </div>
          </div>

          <div className="row p-t-10">
            <div className="col-sm-12">
            <div className="text-center">
                {!props.agreedToTerms && <i className="fa fa-square-o facheckmarrk" onClick={handleshowTerms}></i>}
                {props.agreedToTerms && <i className="fa fa-check-square-o facheckmarrk" onClick={handleshowTerms}></i>}
                <label className="m-l-5">I agree to</label>
                <a href="javascript:void(0)" className="underlineTx" onClick={handleshowTerms}> Terms </a>
            </div>
            </div>
          </div>

          {(props.isLoginForm === 'N') &&
              <div className="row p-t-10 pull-right">
                <Button type="submit" variant="primary" disabled={!props.agreedToTerms || savingEntity} className="m-r-5">
                  {savingEntity &&<i className='fa fa-circle-o-notch fa-spin m-r-5'></i>}
                  Save
                </Button>
                <Button variant="secondary" onClick={()=>{props.closeSubscriptionModal()}}>
                  Cancel
                </Button>
              </div>
          }

          {(props.isLoginForm === 'Y') &&
              <div className="row p-t-10 text-center">
                  <Button type="submit" variant="primary" disabled={!props.agreedToTerms || savingEntity} style={{display:'block', margin:'auto'}}>
                  {savingEntity &&<i className='fa fa-circle-o-notch fa-spin m-r-5'></i>}
                  Create Account
                </Button>
              </div>
          }

        </div>
      </div>
      </form>

      {/* Trems */}
      <div className="row">
        <div className="col-sm-12">
          <Modal 
          show={showTerms} 
          onHide={handleCloseTerms}
          size="lg" dialogClassName='popupBodyConsub' 
          aria-labelledby="contained-modal-title-vcenter"
          centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Subscription Terms</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="iframeHolder" id="iframeHolder" style={{padding: 0,height: 400}} ref={iframeRef}></div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={()=>{handleTermsResponse(true)}}>
                Accept
              </Button>
              <Button variant="secondary" onClick={()=>{handleTermsResponse(false)}}>
                Decline
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>

      {/* Plan Details */}
      <div className="row">
        <div className="col-sm-12">
          <Modal 
          show={showPlanDetails} 
          onHide={handleClosePlanDetails}
          size="lg" dialogClassName='popupBodyConsub'  
          aria-labelledby="contained-modal-title-vcenter"
          centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Plan Details</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="iframeHolder" id="iframeHolder" style={{padding: 0,height: 400}} ref={iframePlanRef}></div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={()=>{handleClosePlanDetails()}}>
                close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
    
  )
}

export default SubscriptionForm;